.promo-over-sa {
  background: rgba(#fff, 0.95);
  position: fixed !important;
  overflow-y: auto;
  overflow-x: hidden;
  @include position(fixed, $z-index: 10000, $top: 0, $left: 0, $right: 0, $bottom: 0);
  padding: 65px 0 0;
  position: relative;
  background: #f7f7f7;
  .promo-over-sa--head {
    height: 59px;
    padding: 0 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 800;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background-image: linear-gradient(-99deg, #04a0f9 3%, #08b1f9 47%, #08b1f9 100%);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    .promotions-sa-title {
      width: 100%;
      h1 {
        font-size: 19px;
        font-weight: normal;
        text-align: center;
      }
    }
  }

  .promo-over-sa--content {
    padding: 20px 30px;
    padding-bottom: 77px + 20px;

    .banner-inner {
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;
      border-radius: 10px;

      .banner-inner-wrap img {
        width: 100%;
        border: 1px solid #fff;
        border-radius: 10px;
      }
      .banner-body {
        padding: 0 15px 9px;
        font-size: 13px;
        color: #666666;
        position: relative;
        span {
          float: right;
          color: #46aef7;
          font-size: 13px;
          padding-right: 17px;
          position: relative;
          &:after {
            background: url('../../img/promotions/go-next.svg') no-repeat center center/cover;
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 12px;
            height: 11px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

// .promo-over-sa {
// 	background: rgba(#fff, .95);
// 	position: fixed !important;
// 	overflow-y: auto;
// 	overflow-x: hidden;
// 	@include position(fixed, $z-index: 10000, $top: 0, $left: 0, $right: 0, $bottom: 0);
// 	.promo-over-sa--head {
// 		position: relative;
// 		padding: 20px;
// 		h2 {
// 			text-align: center;
// 			color: #212533;
// 			@include font(20px, 500);
// 		}
// 		button {
// 			@include position(absolute, $top: 20px, $right: 20px);
// 		}
// 	}
// 	.promo-over-sa--content {
// 		padding: 0 20px 20px;
// 		.banner {
// 			display: block;
// 			padding: 3px;
// 			@include radius(5px);
// 			@include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
// 			@include gradient2(to right, #c1ea4f 0%, #8bdc65 45%, #16885f 100%);
// 			&:not(:last-child) {
// 				margin: 0 0 15px;
// 			}
// 			&-inner {
// 				background: #fff;
// 				@include radius(2px);
// 				img {
// 					width: 100%;
// 					display: block;
// 				}
// 			}
// 			.banner-title {
// 				padding: 10px;
// 				h4 {
// 					@include font(16px, 500);
// 				}
// 			}
// 			.banner-body {
// 				padding: 10px;
// 				display: flex;
// 				align-items: flex-start;
// 				justify-content: space-between;
// 				p {
// 					flex: 1;
// 					@include font(15px, 400);
// 				}
// 				button {
// 					color: #fff;
// 					padding: 4px 14px;
// 					@include font(14px, 500);
// 					@include radius(60px);
// 					@include gradient2(to right, #c1ea4f 0%, #8bdc65 45%, #16885f 100%);
// 				}
// 			}
// 		}
// 		&.single {
// 			.banner:last-child {
// 				margin: 0;
// 			}
// 		}
// 	}
// }
