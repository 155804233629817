.transfer-desktop {
  position: relative;
  .transfer-boxes-loadmore {
    text-align: center;
    margin-top: 20px;
    span {
      padding-right: 20px;
      position: relative;
      color: #dcd8d8;
      font-size: 15px;
      cursor: pointer;
      &.show{
        &:after{
          transform: rotate(180deg) translateY(50%);
        }
      }
      &:after {
        content: "";
        background: url(../../../../assets/img/withdraw/up-arrow.png) no-repeat center center/cover;
        width: 12px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .auto-transfer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    top: -40px;
    padding: 12px;
    @media (min-width: 1200px) {
       padding: 0;
       background-color: transparent;
       box-shadow: none;
       margin: 0;
       width: 120px;
    }
    p {
       margin: 0;
    }
    .auto-transfer-switch {
      position: relative;
      display: inline-block;
      width: 43px;
      height: 23px;
      div {
          position: absolute;
          z-index: 5;
          line-height: 22px;
          color: #fff;
          font-size: 11px;
          &:nth-child(1) {
              left: 7px;
          }
          &:nth-child(2) {
              right: 7px;
          }
      }
      input {
          opacity: 0;
          width: 0;
          height: 0;
      }
      input:checked + .auto-round span {
          -webkit-transform: translateX(21px);
          -ms-transform: translateX(21px);
          transform: translateX(21px);
      }
      input:checked + .auto-round {
        background-color: #06abf9;
      }
      .auto-round {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 30px;
        background-color: #c3c2c7;
        box-shadow: 0 0 1px #ebebeb;
        -webkit-transition: .4s;
        transition: .4s;
        span {
            position: absolute;
            text-align: center;
            line-height: 22px;
            font-size: 11px;
            height: 19px;
            width: 18px;
            left: 2px;
            bottom: 2px;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 26px;
            z-index: 6;
            background-color: #fff;
        }
      }
    }
  }
  .user-balance {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px;

    .user-balance-wrap {
      display: flex;
      background: #fff;
      padding: 4.5px 0;
      border-radius: 16px;
      position: relative;
      &.refresh {
        border: 1px solid #d7d5d5;
        width: 155px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          width: 21px;
          height: 21px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          display: inline-block;
          margin-right: 5px;
          background-image: url(../../../../assets/img/profile/svg/fast-transfer.svg);
        }
      }
      .user-balance-label {
        font-size: 14px;
        color: #39404d;
        display: flex;
        align-items: center;
      }
      .user-balance-amount {
        padding-left: 10px;
        color: #fc686f;
        background: linear-gradient(-46deg, #ff934c, #fc686f);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        font-weight: bold;
        line-height: 23px;
      }
      span {
        display: inline-block;
      }
    }
  }

  .transfer-hr {
    width: 100%;
    border: none;
    height: 1px;
    background: #e9edf6;
  }

  .balances-list {
    padding: 17px 0 5px;
    .balances-list--item {
      height: 110px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      width: 23.125%;
      margin: 0 2.5% 22px 0;
      border: 1px solid #dbe3f1;
      box-shadow: 0 1px 5.52px 2.48px rgba(0, 0, 0, 0.02);
      padding: 9.5px 0;
      border-radius: 10px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-last-child(-n + 4) {
        margin-bottom: 0;
      }
      .game-name {
        white-space: nowrap;
        @include font(14px, normal);
        color: #39404d;
      }
      .game-balance {
        color: #4796ff;
        margin: 10px 0;
        @include font(14px, normal);
      }
      .game-button {
        cursor: pointer;
        border: 1px solid #4796ff;
        color: #4796ff;
        width: 79px;
        padding: 2px;
        border-radius: 12.5px;
        display: block;
        margin: 0 auto;
        font-size: 14px;
        &:hover {
          background-color: rgb(71, 150, 255);
          color: #fff;
        }
      }
    }
  }

  .to-wallet {
    margin-left: auto;
    display: flex;
    align-items: center;
    span {
      margin: 0 0 0 10px;
      color: #16885f;
      @include font(18px, 400);
    }
  }

  .form-field-section {
    padding: 17px 0 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .bottom {
    justify-content: flex-start;
  }

  .input--wrap.with-dot {
    position: relative;
    &:before {
      content: "";
      width: 10px;
      height: 6px;
      display: block;
      pointer-events: none;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuNjcyODYxIDBIOS4zMjcxNEM5LjkyNTg0IDAgMTAuMjI1MiAwLjc2NDg5OCA5LjgwMTM5IDEuMjEzMTZMNS40NzU5MyA1Ljc5MTg4QzUuMjEzNTggNi4wNjkzNyA0Ljc4NjQyIDYuMDY5MzcgNC41MjQwNyA1Ljc5MTg4TDAuMTk4NjA4IDEuMjEzMTZDLTAuMjI1MTkzIDAuNzY0ODk4IDAuMDc0MTU4NiAwIDAuNjcyODYxIDBaIiBmaWxsPSIjOEU5QkFFIi8+PC9zdmc+);
      @include position(absolute, $top: calc(50% - 3px), $right: 16px);
    }
    .input-like {
      font-size: 14px;
      color: #39404d;
    }
  }

  .form-field--doubler {
    width: 49%;
    display: flex;

    .form-field {
      width: 100%;
      margin: 0;
      label {
        font-size: 13px;
        color: #898d95;
        padding: 0;
      }
    }

    .form-field-swap {
      width: 20px;
      height: 16px;
      margin: 0 10px;
      padding: 0 0 30px;
      #swapButton {
        margin-top: 33px;
        background-image: url(../../../../assets/img/profile/svg/vice-versa-arrows.svg);
        width: 23px;
        height: 23px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .with-max {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    .amount-wrap {
      flex: 1;
      .input--wrap {
        padding-left: 20px;
        position: relative;
        span {
          color: #39404d;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
        }
        input {
          color: #898d95;
          font-size: 14px;
          padding: 8px 19px;
          &::placeholder {
            color: #898d95;
          }
        }
      }
    }
    .amount-max-wrap {
      margin: 0 0 0 5px;
      #maxButton {
        width: 109px;
        margin: 0;
        white-space: nowrap;
        color: #4796ff;
        background: none;
        border: 1px solid #4796ff;
        padding: 7px 12px;
        @include radius(5px);
        @include font(14px, normal);
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }

  .amount-variants {
    margin: 0;
    #amountVariants {
      box-sizing: border-box;
      width: 70px;
      height: 30px;
      margin: 0;
      margin-right: 10px;
      white-space: nowrap;
      color: #4796ff;
      background: none;
      border: 1px solid #4796ff;
      box-shadow: none;
      padding: 0 12px;
      @include radius(5px);
      @include font(14px, normal);
    }
  }

  .submit {
    button {
      height: 30px;
      text-align: center;
      margin: 0;
      font-size: 14px;
      border-radius: 5px;
      color: #fff;
      padding: 0;
      background: linear-gradient(90deg, #08b1f9, #04a0f9);
      width: 100px;
    }
  }

  .modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.25);
    &.shown {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .game-sa-overlay {
      margin: auto;
      width: 328px;
      @media screen and (max-width: 345px) {
        width: 310px;
      }

      &.picker-over {
        .picker-container {
          cursor: pointer;
          background: #fff;
          border-radius: 15px;
          margin-bottom: 7px;
          overflow: hidden;
          font-size: 14px;
          .picker-highlight {
            border-radius: 5px;
            box-shadow: 0 1px 24px rgba(0, 0, 0, 0.06);
            &::before {
              height: 0;
            }
            &::after {
              height: 0;
            }
          }
        }
        .picker-head {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(#c3cfe2, #f5f7fa);
          height: 50px;

          p {
            color: #04a0f9;
            @include font(14px, 500, 26px);
            background: linear-gradient(-46deg, #04a0f9, #08b1f9);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .picker-footer {
          display: flex;
          justify-content: space-between;
          button {
            background-image: linear-gradient(#bbbcbc, #ebebe8);
            border-radius: 5px;
            width: 48%;
            height: 50px;
            color: #fff;
            @include font(14px, 400, 29px);
            &.active {
              background-image: linear-gradient(#04a0f9, #08b1f9);
            }
          }
        }
        .picker-wrap {
          background: #fff;
          padding: 20px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-bottom: 7px;
          position: relative;
          .bank-picker-body-wrap{
            height: 205px;
            overflow: auto;
            position: relative;
            z-index: 1;
            padding: 30px 10px 90px;
            &::-webkit-scrollbar {
              width: 7px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 3.5px;
            }
            &::-webkit-scrollbar-thumb {
              background: #edeaea;
              border-radius: 3.5px;
            }
            .bank-picker-item {
              text-align: center;
              font-size: 11.3px;
              color: #acadaf;
              padding: 9px 0;
              transition: all 0.5s ease-in-out;
              &.active{
                box-shadow: 0px 1px 16.56px 7.44px rgba(0, 0, 0, 0.06);
                padding: 17.5px 0;
                border-radius: 5px;
              }
            }
          }
        }
      }

      .overlay-layer {
        background: rgba(#fff, 0.95);
        overflow: hidden;
        @include radius(15px);
        @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
        .load-spin {
          @include radius(10px);
        }
      }

      .form-head {
        height: 50px;
        text-align: center;
        position: relative;
        h2 {
          color: #212533;
          @include font(20px, 500, 1.4);
        }
        button.close {
          @include size(32px, 32px);
          @include position(absolute, $top: 19px, $right: 19px);
        }
      }
      .form-body {
        padding: 26px 18px;
        .form-field {
          &:nth-child(3) {
            margin: 0 0 50px;
          }
          input {
            background: #fff;
            padding-right: 42px;
          }
          .input-like {
            background: #fff;
            padding: 10.5px 42px 10.5px 16px;
            width: 100%;
            color: #8e9bae;
            border: 1px solid #dfebef;
            @include radius(5px);
            @include font(18px, 500, 1.4);
            &.has-value {
              color: #55687d;
            }
          }
        }
        text-align: center;
        button {
          color: #fff;
          @include font(14px, 400, 20px);
          @include radius(5px);
          background-image: linear-gradient(#04a0f9, #08b1f9);
          width: 160px;
          height: 50px;
        }
      }
      .form.response {
        .form-head {
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(#c3cfe2, #eaeef5);
          h2 {
            color: #04a0f9;
            @include font(18px, 500, 26px);
            background: linear-gradient(-46deg, #04a0f9, #08b1f9);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          i {
            background: url(../../../../assets/img/profile/svg/correct.svg)
              no-repeat center center/contain;
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
        }
        .form-body {
          padding: 26px 18px;
          p {
            color: #55687d;
            @include font(19px, 500, 1.4);
          }
          .form-submit {
            margin: 15px 0 0;
            text-align: right;
            button {
              padding: 4px 35px;
            }
          }
        }
      }
      .picker-item {
        color: #8e9bae !important;
      }
      .picker-item-selected {
        color: #55687d !important;
      }
      .picker-container {
        .picker-highlight:after,
        .picker-highlight:before {
          background-color: #dfebef !important;
        }
      }
    }
  }

  .ui-alert-sa {
    &.shown {
      // touch-action: none;
      position: fixed;
    }
  }
}
