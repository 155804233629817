.login-form-sa {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: url('../../img/login/login_bg.png') no-repeat center center/cover;

  .login-form {
    &-wrap {
      // margin: auto;
      margin: auto;
      width: 466px + 20px * 2;
      padding: 20px 24px 50px;
    }
    &-outer {
      .load-spin {
        @include radius(10px);
        background: none;
      }
      overflow: hidden;
    }
    &-inner {
      padding: 20px 10px 43px;
      @media screen and (min-width: 520px) {
        padding: 40px 50px;
      }
    }
  }
  .divider {
    text-align: center;
    position: relative;
    color: #9ddbf9;
    font-size: 12px;
    margin-bottom: 10px;
    font-family: 'PingFang Regular';
    &:before {
      position: absolute;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.264) 11%,
        rgba(255, 255, 255, 0.516) 24%,
        rgb(255, 255, 255) 57%
      );
      width: 100px;
      height: 1px;
      top: 50%;
      left: 5%;
    }
    &:after {
      position: absolute;
      content: '';
      background: linear-gradient(
        90deg,
        rgb(255, 255, 255) 43%,
        rgba(255, 255, 255, 0.516) 68%,
        rgba(255, 255, 255, 0.264) 89%
      );
      width: 100px;
      height: 1px;
      top: 50%;
      right: 5%;
    }
    @media screen and (max-width: 375px) {
      &::before,
      &:after {
        width: 80px;
      }
    }
  }

  .sub-logo {
    background: url('../../img/login/FC-Augsburg_logo.svg') no-repeat center center/contain;
    margin: 12px auto 5px;
    @include size(25px, 32px);
  }
  .sub-text {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    margin-bottom: 25px;
    font-family: 'PingFang Regular';
  }

  .ui-tabs {
    box-shadow: none;
    background: none;
    margin-bottom: 5px;
    .ui-tabs--tab {
      padding: 11px;
      color: #fff;
      font-size: 14px;
      font-family: 'PingFang Medium';
      &.active {
        font-size: 25px;
      }
    }
    .ui-tabs--selector {
      position: absolute;
      bottom: -6px;
      border: 11px solid transparent;
      border-bottom: 11px solid #ffffff;
      @include transition(all 0.2s ease);
    }
    .signin {
      left: 21%;
    }
    .signup {
      left: 71%;
    }
  }
  .logo {
    margin: 0 auto 10px auto;
    background: url('../../img/login/usportslogo.png') no-repeat center center/cover;
    @include size(141px, 100px);
    background-size: contain;
  }
  .form {
    text-align: center;
    &-inner {
      text-align: left;
      padding: 20px;
      padding-top: 30px;
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 30px;
    }
    .restore-wrap {
      margin: 20px 0 0;
      text-align: center;
    }

    button.submit {
      width: 100%;
      font-weight: 400;
      padding: 11px;
      @include radius(5px);
      @include gradient2(100deg, #e1deda, #dddcd7 45%, #d2cec7 100%);
      color: #bcb8b8;
      font-size: 19px;
      margin-top: 10px;
    }
    button.submit.active {
      color: #fff;
      background-image: linear-gradient(90deg, rgb(68, 175, 247) 0%, rgb(49, 193, 239) 54%, rgb(30, 211, 230) 100%);
      background-image: -moz-linear-gradient(
        90deg,
        rgb(68, 175, 247) 0%,
        rgb(49, 193, 239) 54%,
        rgb(30, 211, 230) 100%
      );
      background-image: -webkit-linear-gradient(
        90deg,
        rgb(68, 175, 247) 0%,
        rgb(49, 193, 239) 54%,
        rgb(30, 211, 230) 100%
      );
      background-image: -ms-linear-gradient(90deg, rgb(68, 175, 247) 0%, rgb(49, 193, 239) 54%, rgb(30, 211, 230) 100%);
      box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.24);
    }
    a {
      font-family: 'PingFang Medium';
      font-size: 12px;
      color: #cccccc;
    }
    .form-field {
      margin: 0 0 20px;
    }
    label {
      display: none;
    }

    .form-field--input-wrap {
      position: relative;
      input {
        padding-left: 35px;
        padding-right: 25px;
        background: none;
        color: #999999;
        font-size: 15px;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        &::placeholder {
          color: #999999;
          opacity: 0.8;
        }
        &:-webkit-autofill {
          // background: red;
          -webkit-text-fill-color: #999999;
          -webkit-text-fill-color: none;
          -webkit-box-shadow: 0 0 0px 1000px #fff0 inset;
          box-shadow: 0 0 0px 1000px #fff0 inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }
      input[type='password'] {
        letter-spacing: 1px;
      }
      span {
        content: '';
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 17px;
        padding: 1px;
      }
      span.username {
        background: url('../../img/login/user.svg') no-repeat center center/contain;
      }
      span.password,
      span.passwordok {
        background: url('../../img/login/password.svg') no-repeat center center/contain;
      }

      span.eye {
        background: url('../../img/login/unshow-password.svg') no-repeat center center/contain;
        right: 5px;
        left: unset;
        padding: 9px;
        width: 21px;
        top: 22px;
        height: 13px;
        padding: 2px;
        &.vision {
          background: url('../../img/login/show-password.svg') no-repeat center center/contain;
          width: 19px;
          height: 11px;
        }
      }

      span.telephone {
        background: url('../../img/login/cellphone.svg') no-repeat center center/contain;
      }
      span.agentName {
        background: url('../../img/login/referral.svg') no-repeat center center/contain;
      }
    }
  }
  .backbutton-wrap {
    margin-top: 20px;
    position: absolute;
    bottom: 10px;
    right: 14px;
    a {
      color: #ffffff;
      font-family: 'PingFang Medium';
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
    }
    img {
      transform: rotate(90deg);
      margin-left: 5px;
    }
  }

  .lang-wrap {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 10px;
    left: 0;
    .active {
      color: #f1361d;
    }
    a {
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #ffffff;
    }
    img {
      width: 20px;
      margin-bottom: 5px;
    }
  }

  .auth-forms {
    display: flex;
    align-items: flex-start;
    @include transition(transform 0.2s ease);
    .form {
      min-width: 100%;
      opacity: 0;
      pointer-events: none;
      @include transition(opacity 0.2s ease);
    }
    &.tab--signin {
      //   height: 317px;
      .form--signin {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &.tab--signup {
      @include transform(translateX(-100%));
      .form--signup {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
