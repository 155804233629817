.slider-sa {
  min-width: 320px;
  padding: 8px 5px 7px;
  .slider--item {
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    overflow: hidden;
  }
  .slick-track {
    div {
      outline: none;
      font-size: 0;
      img {
        width: 100%;
        height: 200px;
        display: block;
        object-fit: cover;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .slider--item {
      min-height: 175px;
    }
    .slick-track {
      div {
        img {
          max-height: 175px;
          margin: auto;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    .slider--item {
      min-height: 339px;
    }
    .slick-track {
      div {
        img {
          height: 339px;
        }
      }
    }
  }

  .slick-dots {
    bottom: 0px;
    text-align: right;
    padding-right: 18px;

    li {
      margin: 0 0.5px;
      width: auto;
      button {
        width: 0px;
        &:before {
          border: 1px white solid;
          background-color: transparent;

          width: 7px;
          height: 7px;
          border-radius: 50%;
          opacity: 1;
          content: '';
        }
      }
    }
    .slick-active {
      button:before {
        background-color: white;
      }
    }
  }
}
