.content-sa {
  position: relative;
  z-index: 90;
}

.content-sa-group {
  padding: 0;
  height: 420px;
  overflow-y: auto;
}

.content-sa-native {
  display: flex;
  justify-content: center;
  align-items: center;
  .content-sa-native-items {
    width: 100%;
    height: 420px;
    overflow: auto;
    padding: 0 20px 0 0;
  }
  .content-sa-polygon {
    position: relative;
    background-image: url('../../img/home/side_circle.png');
    background-size: 100% auto;
    width: 60px;
    height: 335px;
    margin-bottom: 20px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.circle-container {
  width: 55px;
  height: 55px;
  position: relative;
  &:nth-child(2),
  &:nth-child(4) {
    margin-left: 19px;
  }
  &:nth-child(3) {
    margin-left: 27px;
  }
  .circle-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    font-size: 10px;
    color: #cccccc;
    transition: all 0.2s ease-in-out;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 55px;
    transition: all 0.1s ease-in-out;
  }
  .blue {
    transform: scale(0.8);
  }
}

.circle-active {
  .circle-text {
    color: #feffff;
  }
  .white {
    opacity: 0;
  }
  .blue {
    transform: scale(1);
  }
}

.card-footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  font-size: 12px;
  font-family: 'PingFang Regular';
  p {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, #f3f8fe, #dddddd);
      right: 105%;
      top: calc(50% - 1px);
      width: 30px;
      height: 1px;
    }
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, #dddddd, #f3f8fe);
      left: 105%;
      top: calc(50% - 1px);
      width: 30px;
      height: 1px;
    }
  }
}

.content-sa-items {
  padding: 12px 0;
  padding-bottom: 64px;
  .sa-group--item {
    cursor: pointer;
    position: relative;
    margin: 0 auto;
    padding: 0 5px;
    margin-bottom: 6px;
    display: block;
    transition: all 0.1s linear;
    width: 95%;
    max-width: 400px;
    .item-text {
      position: absolute;
      font-family: Banner;
      font-size: 30px;
      top: 40%;
      left: 50px;
      background-color: #565656;
      color: transparent;
      text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.2);
      -webkit-background-clip: text;
      background-clip: text;
    }
    img {
      margin: 0 auto;
      width: 100%;
      height: auto;
      transition: all 0.3s ease-in-out;
      &.loading {
        opacity: 0.5;
        height: calc((95vw - 10px) / (640 / 190));
        @media (min-width: 500px) {
          height: calc((390px) / (640 / 190));
        }
      }
      &.native-loading {
        opacity: 0.5;
        height: calc((95vw - 90px) / (11 / 4));
        @media (min-width: 500px) {
          height: calc((390px) / (11 / 4));
        }
      }
    }
    .native-sportcount {
      position: absolute;
      color: #f7f7f7;
      text-shadow: -1px 1px 1px rgba($color: #000000, $alpha: 0.36);
      font-weight: bolder;
      font-size: 18px;
      font-family: 'Sports Count';
      p {
        &:first-child {
          margin-right: 3px;
        }
      }
    }
    .native-sportcount {
      top: 50%;
      transform: translateY(-50%);
      right: 30%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      p {
        &:nth-child(1) {
          font-size: 25px;
        }
        &:nth-child(2) {
          font-size: 14px;
          margin-left: 0px;
          margin-bottom: 4px;
          font-family: 'PingFang Regular';
          font-weight: 600;
        }
      }
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
  .sa-group--item-scroll {
    width: 90%;
  }
}

.content-sa-tabs {
  padding: 0 5px;
  min-width: 320px;
}

.tabs2 {
  font-size: 0;
  width: 100%;
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .tabs-container {
    white-space: nowrap;
    width: 630px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .tab {
    white-space: nowrap;
    color: #3b4e73;
    cursor: pointer;
    text-align: center;
    font-family: 'Pingfang Bold';
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
    @include position(relative, $z-index: 100);
    @include transition(color 0.2s ease-in-out);
    height: 40px;
    line-height: 40px;
    flex: 1;
    width: 80px;
    margin: 0 5px;
    &::before {
      content: '';
      background-image: url('../../img/home/white_tab.png');
      background-repeat: no-repeat;
      background-size: 100% 36px;
      width: 80px;
      height: 36px;
      position: absolute;
      top: 3px;
      left: 0px;
      z-index: -5;
      opacity: 1;
      @include transition(opacity 0.2s ease-in-out);
    }
    &.active {
      color: #fff;
      &::before {
        opacity: 0;
      }
    }
  }
  .tab--switch {
    width: 80px;
    height: 38px;
    @include select(none);
    @include position(absolute, $z-index: 50, $top: 4px);
    @include transition(width 0.2s ease-in-out, transform 0.2s ease-in-out);
    background-image: url('../../img/home/blue_tab.png');
    background-repeat: no-repeat;
    background-size: 100% 38px;
  }
}