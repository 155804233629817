.game-sa {
  height: 100vh;
  padding: 60px 0 0;
  position: relative;
  &.full-screen {
    padding: 0;
    iframe {
      height: calc(100vh - 50px);
    }
    button.full-screen-exit {
      @include transform(translateY(0));
    }
  }
  button.full-screen-exit {
    background: #fff;
    font-size: 0;
    border: 1px solid #fff;
    @include radius(50%);
    @include position(fixed, $z-index: 1000, $top: 12px, $right: 12px);
    @include transition(transform 0.2s ease);
    @include transform(translateY(-200%));
    i {
      background: url('../../img/game/cancel.svg') no-repeat center center/cover;
      width: 30px;
      height: 30px;
      display: block;
    }
  }
  &-head {
    height: 50px;
    min-width: 320px;
    background: linear-gradient(-99deg, #04a0f9 3%, #08b1f9 47%, #08b1f9 100%);
    display: flex;
    align-items: center;
    @include position(fixed, $z-index: 100, $top: 0, $left: 0, $right: 0, $bottom: auto);
    @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
    @include transition(transform 0.2s ease);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    &.hidden {
      @include transform(translateY(-100%));
    }
    button {
      padding: 0 18px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #16885f;
        pointer-events: none;
      }
    }
    .sa-head-middle {
      flex: 1;
      h1 {
        color: #fff;
        text-align: center;
        @include font(19px, normal);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .sa-head-left,
    .sa-head-right {
      min-width: 106px;
    }
    .sa-head-left {
      button {
        margin-left: 22px;
        &:after {
          background: url('../../img/common/back.svg') no-repeat center center/contain;
          width: 12px;
          height: 22px;
          content: '';
        }
      }
    }
    .sa-head-right {
      display: flex;
      align-items: center;
      button.balance {
        padding-right: 5px;
        i {
          background: url('../../img/game/transfer-currency.svg') no-repeat center center/cover;
          width: 25px;
          height: 25px;
        }
      }
      button.refresh {
        padding-left: 0;
        padding-right: 0;
        i {
          background: url('../../img/common/refresh_game.svg') no-repeat center center/cover;
          width: 25px;
          height: 25px;
        }
      }
      button.full-screen {
        padding-left: 5px;
        margin-right: 10px;
        // svg {
        // 	width: 17px;
        // }
        i {
          background: url('../../img/game/fullscreen.svg') no-repeat center center/cover;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  &-content {
    iframe {
      height: calc(100vh - 100px);
      display: block;
    }
  }
  .game-sa-overlay {
    background: rgba(#8e9bae, 0.6);
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    @include flex-center;
    @include position(fixed, $z-index: 1000, $top: 0, $right: 0, $bottom: 0, $left: 0);
    @include transition(opacity 0.2s ease);
    &.shown {
      opacity: 1;
      pointer-events: auto;
    }
    &.picker-over {
      z-index: 1001;
      overflow: hidden;
      align-items: flex-end;
      .picker-container {
        background: #fff;
      }
      .picker-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px 0;
        button {
          color: #16885f;
          @include font(20px, 400, 29px);
        }
        p {
          color: #212533;
          @include font(18px, 500, 26px);
        }
      }
      .picker-wrap {
        padding: 20px;
      }
    }
    .overlay-sublayer {
      margin: auto;
      padding: 40px 0;
      .overlay-layer {
        width: 315px;
        // background: rgba(#fff, .95);
        @include radius(10px);
        @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
        .load-spin {
          @include radius(10px);
        }
      }
    }
    .form-head {
      padding: 18px 0;
      text-align: center;
      position: relative;
      background: linear-gradient(#c3cfe2, #f5f7fa);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      h2 {
        // color: #212533;
        // @include font(20px, 500, 1.4);
        font-size: 14px;
        background: linear-gradient(-45deg, #04a0f9 72%, #08b1f9);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      }
      button.close {
        @include size(32px, 32px);
        @include position(absolute, $top: 19px, $right: 19px);
        display: none;
      }
    }
    .form-body {
      .form-body-wrap {
        background: #fff;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 25px 18px;
        margin-bottom: 7px;
      }
      .form-field {
        &:nth-child(3) {
          // margin: 0 0 50px;
          position: relative;
          border: 1px solid #e1e2e5;
          border-radius: 5px;
        }
        label {
          color: #39404d;
          font-size: 14px;
          position: absolute;
          left: 14px;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          margin: 0;
        }
        .input--wrap {
          width: calc(100% - 100px);
          margin-left: auto;
        }
        input {
          background: #fff;
          padding: 10px;
          border: 0;
          text-align: right;
        }
        .input-like {
          background: #fff;
          padding: 10.5px 42px 10.5px 16px;
          width: 100%;
          color: #8e9bae;
          border: 1px solid #dfebef;
          @include radius(5px);
          @include font(18px, 500, 1.4);
          &.has-value {
            color: #55687d;
          }
        }
        .input--wrap.with-dot {
          position: relative;
          &:before {
            content: '';
            width: 10px;
            height: 6px;
            display: block;
            pointer-events: none;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuNjcyODYxIDBIOS4zMjcxNEM5LjkyNTg0IDAgMTAuMjI1MiAwLjc2NDg5OCA5LjgwMTM5IDEuMjEzMTZMNS40NzU5MyA1Ljc5MTg4QzUuMjEzNTggNi4wNjkzNyA0Ljc4NjQyIDYuMDY5MzcgNC41MjQwNyA1Ljc5MTg4TDAuMTk4NjA4IDEuMjEzMTZDLTAuMjI1MTkzIDAuNzY0ODk4IDAuMDc0MTU4NiAwIDAuNjcyODYxIDBaIiBmaWxsPSIjOEU5QkFFIi8+PC9zdmc+');
            @include position(absolute, $top: calc(50% - 3px), $right: 16px);
          }
        }
      }
      .form-submit {
        text-align: center;
        button {
          color: #fff;
          padding: 10px 40px;
          @include font(14px, normal, 35px);
          @include radius(5px);
          // @include gradient2(93deg, #c1ea4f 5%, #8bdc65 45%, #16885f 100%);
          @include gradient2(-46deg, #04a0f9, #08b1f9);
          width: 100%;
        }
      }
    }
    .form.response {
      .form-head {
        padding: 20px 30px 15px;
        h2 {
          text-align: left;
        }
      }
      .form-body {
        padding: 0 30px 25px;
        p {
          color: #55687d;
          @include font(19px, 500, 1.4);
        }
        .game-transfer-p {
          font-size: 14px;
          color: #39404d;
          margin: 20px 0 40px;
          text-align: center;
        }
        .form-submit {
          margin: 15px 0 0;
          text-align: right;
          button {
            padding: 4px 35px;
          }
        }
      }
      .not-message {
        &.form-head {
          h2 {
            text-align: center;
            position: relative;
            display: inline-block;
            padding-left: 30px;
            &:before {
              content: '';
              background: url('../../img/profile/svg/correct.svg') no-repeat center center/cover;
              width: 24px;
              height: 24px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            &.hide {
              &:before {
                display: none;
              }
              padding: 0;
            }
          }
        }
        &.form-body {
          background: #fff;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          padding: 25px;
          .form-submit {
            margin: 0;
            button {
              width: 160px;
              margin: auto;
              display: block;
            }
          }
        }
      }
    }
    .picker-item {
      color: #8e9bae !important;
    }
    .picker-item-selected {
      color: #55687d !important;
    }
    .picker-container {
      .picker-highlight:after,
      .picker-highlight:before {
        background-color: #dfebef !important;
      }
    }
  }
  .slider-wrap {
    display: flex;
    align-items: center;
    p {
      color: #4796ff;
      @include font(15px, 500);
    }
    .slider-subwrap {
      flex: 1;
      margin: 0 14px;
      min-width: 0;

      .slider-picker {
        height: 14px;
        .track {
          background: linear-gradient(90deg, #c3cfe2 150%, #f5f7fa);
          height: 4px;
        }
        .thumb {
          border: 1px solid #e1e2e5;
          background: linear-gradient(-45deg, #04a0f9 72%, #08b1f9);
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.transfer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .transfer--item {
    text-align: center;
    .title {
      color: #bdbdbe;
      @include font(12px, normal);
    }
    .game {
      color: #39404d;
      margin: 4px 0;
      @include font(14px, normal);
      border: 1px solid #e1e2e5;
      padding: 3px;
      border-radius: 13.5px;
    }
    .balance {
      color: #4796ff;
      @include font(15px, 500);
    }
    &:not(.icon) {
      width: 40%;
    }
    &.icon {
      position: relative;
      svg {
        fill: #4796ff;
        width: 12px;
        height: 12px;
        @include transform(rotate(180deg));
        position: relative;
        left: -2px;
        z-index: 2;
      }
      &:before {
        content: '';
        position: absolute;
        left: -4px;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        width: 5px;
        height: 6px;
        z-index: 1;
      }
      &:after {
        width: 16px;
        height: 16px;
        content: '';
        border: 1px solid #4796ff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }
    }
  }
}
