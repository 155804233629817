.native {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 120px;
  padding-bottom: 83px;
  position: relative;
  &.competition-results {
    padding-top: 179px;
    .native-main-header {
      background: #ffffff;
      border-radius: 0;
      box-shadow: none;
    }
  }
  .loadmore,
  .nomore {
    text-align: center;
    padding: 20px 0 0;
    font-size: 20px;
    background: -webkit-linear-gradient(90deg, #8ce6f0, #41b2f6);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: PingFang Bold;
  }
}

.native-main-header {
  position: fixed;
  top: 0;
  left: 0;
  background-image: url('../../img/native/native-header-bg.png');
  background-size: 110%;
  background-position: center;
  z-index: 21;
  border-radius: 0 0 10px 10px;
  box-shadow: 1.5px 2.598px 6px 0px rgba(51, 51, 51, 0.16);
}

@keyframes dropdownHeader {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes dropdownHeaderMin {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

.native-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  position: relative;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-left: 40px;
  &.virtual-header {
    justify-content: center;
    padding: 6px 0px 6px 0px;
    .native-header-tabs {
      justify-content: center;
    }
  }
  .native-header-refresh {
    width: 18px;
    position: absolute;
    top: 12px;
    left: 10px;
    padding: 5px;
  }
  .favorite-header {
    width: 100%;
    text-align: center;
    padding: 6px 40px 6px 0;
    p {
      font-family: 'PingFang Bold';
      font-weight: bolder;
      font-size: 18px;
      background: linear-gradient(0deg, #40b3f5 0%, #20d1e7 50%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }
  .native-header-tabs {
    width: calc(100% - 105px);
    height: 37px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .market {
      position: relative;
      padding: 0 10px;
      &:first-child {
        padding-left: 56px;
      }
      &:last-child {
        padding-right: 56px;
      }
      p {
        font-family: 'PingFang Medium';
        color: #ccc;
        white-space: nowrap;
        pointer-events: none;
        &::before,
        &::after {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
          width: 20px;
          height: 2px;
          background-color: #2fc3ee;
          border-radius: 2px;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 6px;
          height: 6px;
          background-color: #22cfe8;
          border: 1px solid #eeeeee;
          border-radius: 6px;
        }
      }
      img {
        position: absolute;
        top: -3px;
        right: 0px;
        width: 10px;
        filter: grayscale(100%);
      }
      &.active {
        p {
          font-family: 'PingFang Bold';
          font-weight: bolder;
          font-size: 18px;
          background: linear-gradient(0deg, #40b3f5 0%, #20d1e7 50%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          position: relative;
          &::before,
          &::after {
            opacity: 1;
          }
        }
        img {
          filter: grayscale(0%);
        }
      }
    }
  }
  .native-toggle-sw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    position: absolute;
    top: 10px;
    right: 10px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 25px;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 46px;
      height: 23px;
      div {
        position: absolute;
        z-index: 10;
        line-height: 23px;
        color: #929292;
        font-size: 11px;
        &:nth-child(1) {
          left: 8.5px;
        }
        &:nth-child(2) {
          right: 8.5px;
        }
      }
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input:checked + .slider span {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
      }
      .round {
        border-radius: 30px;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #eaeaea;
        box-shadow: 0 0 1px #ebebeb;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        span {
          position: absolute;
          text-align: center;
          line-height: 22px;
          font-size: 11px;
          height: 23px;
          width: 22px;
          left: 0px;
          bottom: 0px;
          background-image: url('../../img/native/toggle_bg.png');
          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 26px;
          z-index: 15;
          color: #fff;
        }
      }
    }
  }
}

.sportstabs {
  width: 100vw;
  overflow-x: auto;
  padding: 5px 0 10px 0;
  &::-webkit-scrollbar {
    display: none;
  }
  .sportstabs-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .sportstab {
    padding: 0 30px 0 15px;
    text-align: center;
    div {
      position: relative;
      img {
        width: 40px;
        filter: grayscale(1);
        opacity: 0.35;
        transition: 0.3s all ease-in-out;
      }
    }
    p {
      color: #dad8d9;
      position: relative;
      transition: 0.2s all ease-in-out;
      white-space: nowrap;
      font-family: Trends;
      span {
        position: absolute;
        font-size: 12px;
        bottom: 50%;
        left: 100%;
      }
    }
  }
  @keyframes sport_menu {
    0% {
      transform: scale(0) rotateY(0deg);
    }
    100% {
      transform: scale(1) rotateY(360deg);
    }
  }
  .active {
    div {
      img {
        filter: grayscale(0);
        opacity: 1;
        animation: sport_menu 0.3s ease-out forwards;
      }
    }
    p {
      color: #46aef7;
    }
  }
}

@keyframes leftarrow {
  0% {
    transform: translateX(0);
    opacity: 0.4;
  }
  100% {
    transform: translateX(-0.1em);
    opacity: 0.9;
  }
}

.native-champion {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
  left: 0;
  z-index: 20;
  background-color: #ffffff;
  padding: 15px 0 10px 0;
  &.stick-below-date {
    padding: 10px 0;
    top: 183px;
  }
  &.favorite-champion {
    top: 93px;
    padding: 10px 0;
    background-color: #f3f8fe;
  }
  &.virtual-champion {
    top: 103px;
    background-color: #f3f8fe;
    padding: 5px 0 10px;
  }
  .native-champion-header {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    margin: 2px 2px 0;
    align-items: center;
    background-image: url('../../img/native/champion_bg.png');
    background-size: 100% 41px;
    background-position: 0 -1px;
    border-radius: 5px;
    height: 37px;
    img {
      width: 15px;
      transform: rotate(0deg);
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 25px;
        filter: invert(100%);
        transform: rotateX(0deg);
        transition: all 0.3s ease-in-out;
      }
      p {
        color: #fff;
        font-family: PingFang Bold;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
  }
}

.gamechart-minimize {
  .gamechart-drop-container {
    height: 0px !important;
  }
  .gamechart-header-min {
    transform: rotate(90deg) rotateY(-180deg) !important;
  }
}

.gamechart {
  margin: 0px 5px 10px;
  border-width: 1px;
  border-color: rgb(230, 230, 230);
  border-style: solid;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.16);
  overflow: hidden;
  .gamechart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 10px;
    background-color: #fff;
    height: 41px;
    * {
      pointer-events: none;
    }
    div {
      width: calc(100% - 15px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        max-width: 20px;
        max-height: 20px;
        margin-right: 5px;
      }
    }
    p {
      color: #42b5f8;
      font-family: 'PingFang Bold';
      font-style: italic;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .gamechart-header-min {
      transition: all 0.3s ease-in-out;
      width: 8px;
      transform: rotate(90deg) rotateY(0deg);
    }
  }
  .gamechart-drop-skeleton {
    width: 100%;
    border-radius: 0;
    background-color: #f5f5f5;
    background-image: linear-gradient( 90deg,#f5f5f5,#fafafa,#f5f5f5 );
  }
}

.gamechart-drop-container {
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  .gamechart-slider-cover {
    width: 49vw;
    position: absolute;
    top: 0;
    left: 34vw;
    z-index: 15;
  }
  .lite-gamechart-drop {
    background-color: #ffffff;
    .lite-odds {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px 10px;
      div {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc((100% / 3) - 5px);
        background-color: #f5f8ff;
        border-radius: 2px;
        padding: 7px 0;
        transition: all 0.2s ease-in-out;
        &:nth-child(2) {
          margin: 0 15px;
        }
        p {
          font-size: 12px;
          font-family: 'PingFang Medium';
          color: #8f9eb2;
          transition: all 0.2s ease-in-out;
        }
        .slider-item-number {
          font-family: 'PingFang Bold';
          color: #384a6e;
          position: relative;
          transition: all 0.2s ease-in-out;
          &::after {
            content: '';
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
            position: absolute;
            top: 50%;
            left: 100%;
            border: 5px solid transparent;
          }
          &.increase {
            color: #1ed68e;
            &::after {
              border-left-color: #1ed68e;
              transform: rotate(-45deg);
              top: -5px;
            }
          }
          &.decrease {
            color: #ff4e34;
            &::after {
              border-left-color: #ff4e34;
              transform: rotate(45deg);
              top: 80%;
            }
          }
        }
      }
      .table-item-active {
        background-color: #42b5f8;
        p {
          color: #fff;
        }
      }
    }
    .lite-teams {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 40px;
      .column {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        text-align: center;
        flex: 1;
        &:nth-child(1),
        &:nth-child(3) {
          flex: 10;
        }
        &:nth-child(1) img {
          margin-left: 5px;
        }
        &:nth-child(3) img {
          margin-right: 5px;
        }
      }
      .name {
        color: #333333;
        font-size: 12px;
        font-family: 'PingFang Bold';
      }
      img {
        max-width: 20px;
        max-height: 20px;
      }
    }
    .lite-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px 0;
      border-top: 1px solid #eee;
      .teams-time {
        font-size: 12px;
        font-family: 'PingFang Bold';
        color: #333333;
        text-align: center;
        white-space: nowrap;
        span {
          color: #42b5f8;
        }
      }
      .star,
      .livestream {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .star {
        p {
          margin-left: 8px;
          padding: 1px 2px 2px;
          font-family: 'PingFang Medium';
          font-size: 10px;
          color: #fff;
          border-radius: 2px;
          background-color: #b1bcce;
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.25);
        }
        div {
          margin-bottom: -1px;
        }
      }
      .livestream {
        div {
          margin-right: 8px;
          margin-top: -1px;
        }
        a {
          font-size: 10px;
          font-family: 'PingFang Medium';
          color: #42b5f8;
          padding: 0 4px 1px;
          border: 1px solid #42b5f8;
          border-radius: 2px;
        }
      }
      .star div,
      .livestream div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          &:nth-child(1) {
            position: absolute;
            margin-left: -1px;
            margin-top: -1px;
            background-color: #eaf6ff;
          }
        }
      }
      .star div {
        img:nth-child(1) {
          width: 16px;
          background-color: transparent;
        }
      }
      .livestream div img {
        width: 18px;
      }
    }
  }
  .gamechart-drop {
    width: 100%;
    display: flex;
    background-color: #eaf6ff;
    position: absolute;
    left: 0px;
    .gamechart-teams {
      width: 35vw;
      margin-right: 2px;
      .teams-header {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 30px;
        padding: 0 5px;
        img {
          width: 15px;
          &:nth-child(1) {
            position: absolute;
            margin-top: -1px;
            background-color: #eaf6ff;
          }
        }
        div {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(1) {
            img {
              width: 17px;
            }
          }
          &:nth-child(2) {
            img {
              width: 16px;
            }
          }
          &:nth-child(3) {
            img {
              width: 16px;
              margin-bottom: -1px;
            }
          }
        }
      }
      .teams-name {
        background-color: #fff;
        padding: 7px 10px;
        margin-bottom: 2px;
        height: 86px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        .team {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name {
          color: #333333;
          font-size: 12px;
          font-family: PingFang Bold;
        }
        img {
          max-width: 20px;
          max-height: 20px;
          margin-right: 5px;
        }
      }
      .teams-time {
        background-color: #fff;
        padding: 0 13px;
        font-size: 12px;
        height: 42px;
        p {
          color: #666666;
          line-height: 42px;
          font-family: 'PingFang Medium';
          white-space: nowrap;
          span {
            color: #42b5f8;
          }
        }
      }
    }
    .gamechart-slider-container {
      display: flex;
      width: 50vw;
      height: 160px;
      overflow: hidden;
      position: relative;
      .gamechart-slider-dots {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        width: 14px;
        height: 3px;
        bottom: 3px;
        right: calc(50% - 4px);
        div {
          width: 3px;
          height: 3px;
          margin: 0 1px;
          border-radius: 3px;
          background-color: #8eccff;
          transition: all 0.3s ease-in-out;
          &:nth-child(1) {
            width: 7px;
          }
        }
      }
      .gamechart-slider-item {
        position: absolute;
        display: flex;
        transition: all 0.3s ease-in-out;
        right: calc((50vw - 4px) * (-1));
        &.one-page {
          right: 0;
        }
        .gamechart-slider {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: calc(50vw - 4px);
          text-align: center;
          font-size: 12px;
          .slider-header {
            height: 30px;
            line-height: 30px;
            color: #8f9eb2;
            font-family: 'PingFang Medium';
            transition: all 0.2s ease-in-out;
          }
          .slider-column {
            flex: 1;
          }
          .slider-item-number {
            color: #333333;
            font-family: 'PingFang Bold';
            font-weight: bolder;
            position: relative;
            transition: all 0.2s ease-in-out;
            pointer-events: none;
            &::after {
              content: '';
              transition: all 0.2s ease-in-out;
              transform: rotate(0deg);
              position: absolute;
              top: 50%;
              left: 100%;
              border: 5px solid transparent;
            }
            &.increase {
              color: #1ed68e;
              &::after {
                border-left-color: #1ed68e;
                transform: rotate(-45deg);
                top: -5px;
              }
            }
            &.decrease {
              color: #ff4e34;
              &::after {
                border-left-color: #ff4e34;
                transform: rotate(45deg);
                top: 80%;
              }
            }
          }
          .slider-item-header {
            font-size: 11px;
            color: #8f9eb2;
            font-family: 'PingFang Regular';
            pointer-events: none;
          }
          .table-item3,
          .table-item2,
          .table-item1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-right: 2px;
            margin-bottom: 2px;
            transition: all 0.2s ease-in-out;
          }
          .table-item-active {
            background-color: #42b5f8;
            p {
              color: #fff;
            }
          }
          .table-item3 {
            height: 42px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .table-item1 {
            margin-right: 0;
          }
          .table-item2,
          .table-item1 {
            height: 65px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .gamechart-number {
      width: 15vw;
      margin-top: 30px;
      margin-left: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      position: relative;
      img {
        position: absolute;
        left: -15px;
        width: 15px;
        top: calc(50% - 0.3em);
        animation: leftarrow 0.6s infinite alternate ease-in-out;
      }
      p {
        font-weight: bolder;
        border-radius: 2px;
        padding: 3px;
        color: #fff;
        font-size: 14px;
        background-image: linear-gradient(89deg, rgb(68, 175, 247) 0%, rgb(49, 193, 239) 54%, rgb(30, 211, 230) 100%);
        background-image: -moz-linear-gradient(
          89deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        background-image: -webkit-linear-gradient(
          89deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        background-image: -ms-linear-gradient(
          89deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        box-shadow: 1px 1.732px 4px 0px rgba(0, 0, 0, 0.26);
        // box-shadow: 0px 3px 4.9px 0.1px rgba(35, 116, 134, 0.36),inset 0px 26px 7px 0px rgba(199, 233, 241, 0.09);
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.native-navbottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 20px;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 40;
  a,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .active {
      color: #42b5f8;
    }
    p {
      font-family: 'PingFang Medium';
      color: #b1bcce;
      font-size: 12px;
    }
    img {
      max-width: 20px;
      max-height: 20px;
    }
    &.loading {
      img {
        animation: loading 1s linear infinite;
      }
    }
  }
}

.native-live {
  position: fixed;
  z-index: 100;
  width: 70vw;
  overflow: hidden;
  background-color: #fff;
  video {
    border-radius: 8px;
  }
  .handle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 30;
  }
  .close {
    position: absolute;
    top: 5px;
    right: 6px;
    width: 19px;
    padding: 5px;
    z-index: 35;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  .fullscreen {
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: 20px;
    padding: 5px;
    z-index: 35;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  .mute {
    position: absolute;
    bottom: 10px;
    left: 5px;
    width: 20px;
    padding: 5px;
    z-index: 35;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
}

.bet-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 67px;
  .bet-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba($color: #333333, $alpha: 0.6);
    z-index: -10;
  }
}

.bet {
  .bet-data {
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    width: 300px;
    padding: 20px;
  }
  .bet-competition {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 20px;
      max-height: 20px;
      margin-right: 5px;
    }
    p {
      font-family: PingFang Bold;
      color: #3b4e73;
      font-style: italic;
    }
  }
  .bet-teams {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4px 0 8px;
    color: #535353;
    p {
      flex: 1.5;
      text-align: center;
      font-family: PingFang Bold;
      color: #999999;
      font-size: 13px;
      &:nth-child(2) {
        flex: 0.7;
      }
    }
  }
  .bet-odds {
    width: 100%;
    height: 84px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;
    font-family: 'PingFang Medium';
    padding-bottom: 5px;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    div {
      margin: 0;
      z-index: 10;
      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin: 0 5px;
          transition: all 0.3s ease-in-out;
          &:nth-child(1) {
            color: #005f8f;
          }
          &:nth-child(2) {
            color: #005f8f;
          }
          &:nth-child(3) {
            font-size: 14px;
            width: 25px;
            height: 25px;
            line-height: 24px;
            font-weight: bolder;
            text-align: center;
            color: #fff;
            border-radius: 50%;
            background-image: linear-gradient(100deg, #038dd0 10%, #29c1f9);
          }
        }
      }
    }
    .handicap {
      p {
        text-align: center;
        color: #fff;
      }
    }
    &.increase {
      div {
        &:first-child {
          p {
            &:nth-child(1) {
              color: #078200;
            }
            &:nth-child(2) {
              color: #078200;
            }
            &:nth-child(3) {
              background-image: linear-gradient(100deg, #4f9c4b 10%, #7ec478);
            }
          }
        }
      }
    }
    &.decrease {
      div {
        &:first-child {
          p {
            &:nth-child(1) {
              color: #bc0000;
            }
            &:nth-child(2) {
              color: #bc0000;
            }
            &:nth-child(3) {
              background-image: linear-gradient(100deg, #d34d4d 10%, #c47878);
            }
          }
        }
      }
    }
  }
  .bet-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .balances {
      font-size: 13px;
      p {
        font-family: 'PingFang Bold';
        font-style: italic;
        color: #3b4e73;
      }
      span {
        color: #8eccff;
        font-family: Sports Count;
        color: #f25562;
      }
    }
    .transfer {
      font-size: 13px;
      background-image: url('../../img/bet/transfer.png');
      background-size: 90px auto;
      background-position: center;
      background-repeat: no-repeat;
      color: #fff;
      width: 90px;
      height: 22px;
      padding-left: 20px;
      border-radius: 20px;
      overflow: hidden;
      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
  .bet-input {
    padding: 7px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: rgb(247, 247, 247);
    box-shadow: inset 0px 0px 7.04px 0.96px rgba(51, 51, 51, 0.08);
    input {
      width: 100%;
      font-family: 'PingFang Medium';
      color: #999999;
      font-size: 13px;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }
  .bet-amounts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      font-family: 'Sports Count';
      font-size: 13px;
      width: calc((100% / 3) - 10px);
      text-align: center;
      color: #8eccff;
      padding: 5px 0;
      margin-bottom: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      background-color: rgb(253, 255, 252);
      box-shadow: 0px 2px 0px 0px rgba(200, 206, 220, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
      transition: color 0.2s ease-in-out;
      &:disabled {
        color: #cccccc;
      }
      &.selected {
        color: #ffffff;
        border: none;
        background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
        box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
      }
    }
  }
  .bet-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-family: PingFang Bold;
    margin-top: 5px;
    color: #3b4e73;
    span {
      font-family: 'Sports Count';
      color: #f25562;
      font-style: italic;
    }
  }
  .bet-btn {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    color: #42b5f8;
    font-size: 18px;
    font-family: 'PingFang Bold';
    font-style: italic;
    position: relative;
    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .bet-slider {
    position: relative;
    margin: 20px -20px -21px;
    height: 44px;
    background-image: linear-gradient(90deg, #058ffc, #3fd2fe);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &.combo {
      margin: 0 0 -1px;
      border-radius: 0px;
    }
    .handle {
      position: absolute;
      left: 0;
      top: 0;
      width: 75px;
      z-index: 10;
      img {
        width: 100%;
      }
    }
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      img {
        width: 25px;
      }
      p {
        margin: 0 10px;
        font-family: 'PingFang Bold';
        color: #dff1ff;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.datefilter {
  overflow-x: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
  left: 0;
  z-index: 20;
  padding: 15px 0 0;
  background-color: #ffffff;
  &::-webkit-scrollbar {
    display: none;
  }
  .datefilter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .datefilter-item {
    min-width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: rgb(253, 255, 252);
    box-shadow: 0px 2px 0px 0px rgba(200, 206, 220, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
    margin: 0 5px;
    font-size: 11px;
    color: #3b4e73;
    font-family: 'PingFang Bold';
    &:first-child,
    &:nth-last-child(2) {
      font-size: 15px;
      font-style: italic;
    }
    p {
      &:nth-child(2) {
        font-style: italic;
      }
    }
  }
  .active {
    color: #fff;
    border: none;
    background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
    box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
  }
  .datefilter-item-spacer {
    min-width: 5px;
    min-height: 48px;
  }
}

.navbottom-menu-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #333333, $alpha: 0.4);
  z-index: -10;
  opacity: 0;
}

.navbottom-menu {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: 35;
  bottom: -27%;
  padding: 15px;
  padding-bottom: 5px;
  background-color: #fff;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  .menu-oddstype {
    width: 100%;
    text-align: center;
    .oddstype-choices {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      p {
        margin: 5px 0;
        font-family: 'PingFang Medium';
        color: #a7acb4;
        margin: 0 7px;
      }
      .active {
        font-family: 'PingFang Bold';
        font-size: 14px;
        color: #42b5f8;
      }
    }
    .oddstype-arrow {
      position: relative;
      width: 163px;
      margin: 10px auto 20px;
      height: 2px;
      border-radius: 2px;
      background-color: #d3d6dd;
      img {
        position: absolute;
        width: 13px;
        top: -7px;
        left: 0px;
      }
    }
  }
  .menu-item {
    width: 25%;
    text-align: center;
    margin-bottom: 10px;
    .menu-icon {
      max-width: 20px;
      max-height: 20px;
      margin-bottom: -5px;
    }
    .menu-choices-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      .menu-choices {
        p {
          margin: 5px 0;
          font-family: 'PingFang Medium';
          color: #a7acb4;
        }
        .active {
          font-family: 'PingFang Bold';
          font-size: 14px;
          color: #42b5f8;
        }
      }
      .menu-arrow {
        margin-left: 10px;
        position: relative;
        width: 2px;
        height: 35px;
        background-color: #d3d6dd;
        border-radius: 2px;
        img {
          position: absolute;
          width: 13px;
          top: -1px;
          left: -5px;
        }
      }
    }
  }
}

.navbottom-menu-bg-active {
  z-index: 30;
  opacity: 1;
}

.navbottom-menu-active {
  bottom: 67px;
  opacity: 1;
}

.event-search {
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: auto;
  scroll-behavior: smooth;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: -50;
  background-color: #f3f8fe;
  font-family: PingFang Medium;
  padding-top: 49px;
  padding-bottom: 50px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; 
  .searchbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    background-image: url('../../img/common/header_bg.png');
    background-size: cover;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 10;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    p {
      color: #384a6e;
      font-size: 14px;
      width: 20%;
      text-align: center;
    }
    input {
      width: 60%;
      margin: 0px;
      padding: 7px 15px;
      border-width: 1px;
      border-color: rgb(242, 242, 242);
      border-style: solid;
      background-color: rgb(250, 250, 250);
      box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
      border-radius: 35px;
      color: #384a6e;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      font-size: 16px;
      &::placeholder {
        text-align: center;
        color: #cccccc;
      }
    }
  }
  .hotgames {
    .title {
      width: 100%;
      text-align: center;
      font-size: 14px;
      padding: 7px 0;
      color: #384a6e;
      box-shadow: 0.75px 1.25px 3px 0px rgba(51, 51, 51, 0.16);
      margin-bottom: 5px;
    }
    .card-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 5px 5px;
      .card {
        width: calc(50% - 10px);
        margin: 5px;
        padding: 7px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.14);
        div {
          width: 12px;
          height: 12px;
          border-radius: 12px;
          border: 1px solid #384a6e;
          margin-right: 7px;
        }
        img {
          width: 15px;
          margin-right: 7px;
        }
        p {
          color: #97a3b1;
          font-size: 14px;
        }
      }
    }
  }
  .competition-card {
    width: calc(100% - 20px);
    margin: 10px 10px 0;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.14);
    padding: 7px 30px 7px 10px;
    position: relative;
    .navigate {
      position: absolute;
      top: -150px;
    }
    .radio {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      border: 1px solid #384a6e;
      margin-right: 7px;
      position: absolute;
      left: 0;
      top: calc(50% - 3.5px);
      transform: translateY(-50%);
    }
    .title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #384a6e;
      font-size: 14px;
      padding-bottom: 7px;
      padding-left: 20px;
      position: relative;
      img {
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        width: 5px;
      }
    }
    .competition-container {
      border-top: 1px solid #eee;
      padding: 7px 0 0 20px;
      .competition {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #97a3b1;
        font-size: 14px;
        margin-bottom: 7px;
        position: relative;
        padding-left: 20px;
        .radio {
          top: 50%;
        }
      }
    }
  }
  .competition-card-minimize {
    .radio {
      top: 50%;
    }
    .title {
      padding-bottom: 0;
      img {
        transform: translateY(-50%) rotate(90deg);
      }
    }
    .competition-container {
      display: none;
    }
  }
  .letter {
    position: fixed;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 14px;
      line-height: 30px;
      width: 30px;
      height: 30px;
      color: #fff;
      border-radius: 50%;
      background-image: url('../../img/native/letter_background.png');
      background-size: 100% auto;
    }
    a {
      font-size: 12px;
      color: #97a3b1;
      transition: all 0.2s ease-in-out;
      &:active {
        transform: translateX(-5px);
      }
    }
  }
  .radio-active {
    .text {
      color: #42b5f8 !important;
    }
    .radio {
      background-image: url('../../img/native/check_competition.png');
      background-size: 100% auto;
      width: 18px !important;
      height: 18px !important;
      border: none !important;
      margin-left: -3px;
    }
  }
}

.event-search-active {
  opacity: 1;
  top: 50px;
  z-index: 50;
}

.hover-buttons {
  position: fixed;
  bottom: 57px;
  right: 10px;
  .refresh {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba($color: #000000, $alpha: 0.18);
    img {
      width: 100%;
    }
  }
}
