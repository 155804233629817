.profile-inbox-desktop{
	// DESKTOP
	.profile-section-message-wrap{
		min-height: 570px;
		max-height: 570px;
		overflow: auto;
		padding: 10px;
		margin-top: 10px;
		position: relative;
		&::-webkit-scrollbar {
			width: 7px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 3.5px;
		}
		&::-webkit-scrollbar-thumb {
			background: #edeaea;
			border-radius: 3.5px;
		}
		.profile-section-message-norecord{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50% , -50%);
			.profile-section-message-norecord-wrap{
				text-align: center;
				i{
					background: url(../../../../assets/img/inbox/mail-box.png) no-repeat center center/contain;
					width: 140px;
					height: 140px;
					display: block;
					margin-bottom: 23px;
				}
				span{
					font-size: 14px;
					color: #7e8795;
				}
			}
		}
	}
	.profile-section-message-list{
		box-shadow: 0px 1px 5.52px 2.48px rgba(0, 0, 0, 0.02);
		.p-section-m-item {
			padding: 25px 30px;
			border-radius: 5px;
			border-bottom: 1px solid #f1efef;
			position: relative;
			.p-section-m-status {
				position: absolute;
				top: 8px;
				right: 14px;
				span{
					color: #a0a1a1;
					font-size: 10px;
					border-radius: 5px;
					padding: 2.5px 12px;
					background: linear-gradient(135deg, #d2cec7, #dddcd7, #e1deda);
					&.unread{
						color: #51afe4;
						background: linear-gradient(135deg, #c2e9fb, #a1c4fd);
					}
				}
			}
		}
	}

	.read-message {
		position: fixed;
		top: 0;
		left: 0;
		background: #0000001a;
		width: 100%;
		height: 100%;
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
		.read-message-wrap {
			max-width: 736px;
			width: 100%;
			background: #fff;
			padding: 27px 24px 20px;
			.read-message-title {
				font-size: 19px;
				color: #46aef7;
				text-align: center;
			}
			.read-message-content {
				padding: 45px 0 60px;
				font-size: 14px;
				.read-message-subject-time {
					color: #46aef7;
					margin-bottom: 20px;
				}
				.read-message-text {
					color: #39404d;
				}
			}
			.read-message-button {
				text-align: center;
				button {
					font-size: 14px;
					color: #fff;
					width: 169px;
					padding: 13px;
					box-shadow: 0px 1px 5.52px 2.48px rgba(0, 0, 0, 0.02);
					background: linear-gradient( -46deg, rgb(8,177,249) 0%, rgb(4,160,249) 100%);
					border-radius: 23px;
				}
			}
			
			  
		}
	}

}
