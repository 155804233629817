.profile-speedapp{
  .speedapp-wrap-inner{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .speedapp-wrap-items{
          background: #fff;
          padding: 25px;
          border-radius: 10px;
          margin-bottom: 10px;
          .speedapp-wrap-caption {
              text-align: center;
              font-size: 14px;
              color: #39404d;
              margin-bottom: 16px;
          }
          
          .speedapp-wrap-thumbnail{
              width: 340px;
              height: 300px;
              margin: auto;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center center;
              @for $i from 1 through 5 {
                  &.app#{$i} {
                      background-image: url(../../../../assets/img/speed-app/share-app_0#{$i}.png);
                  }
              }
              @media screen and (max-width: 425px) {
                  width: 100%;
                  height: 265px;
              }
              @media screen and (max-width: 375px) {
                  height: 234px;
              
              }
              @media screen and (max-width: 320px) {
                  height: 185px;
              
              }
          }
          

      }
      
  }
}