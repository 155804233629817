@import '../../../assets/scss/helpers/template.scss';

.news-wrap {
  .news-wrap-inner {
    .news-wrap-item {
      background: #fff;
      box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.02);
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 28px 20px;
      position: relative;
      .news-wrap-content {
        &.drop {
          .news-wrap-text {
            height: auto;
          }
          .news-wrap-arrow {
            &:before {
              transform: rotate(-180deg) translate(50%, 50%);
            }
          }
        }
      }

      .news-wrap-time {
        color: #46aef7;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .news-wrap-text {
        font-size: 14px;
        color: #39404d;
        height: 22px;
        overflow: hidden;
        margin-bottom: 14px;
      }
      .news-wrap-arrow {
        width: 12px;
        height: 11px;
        position: absolute;
        left: 50%;
        bottom: 13px;
        transform: translateX(-50%);
        &:before {
          content: '';
          background: url('../../img/news/show-more.svg') no-repeat center center/cover;
          width: 12px;
          height: 11px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .news-load.more {
      text-align: center;
      color: #46aef7;
      font-size: 14px;
      padding: 10px;
    }
  }
}
