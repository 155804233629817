.bet-settlement {
  font-family: 'PingFang Medium';
  font-size: 14px;
  padding: 52px 0;
  .header {
    position: relative;
    padding: 10px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 8px;
    }
    .bet-settlement-tabs {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      border-radius: 30px;
      border: 1px solid #e6e6e6;
      margin: 0 auto;
      p {
        padding: 5px 10px;
        color: #999999;
        text-align: center;
        width: 50%;
        z-index: 10;
        transition: all 0.2s ease-in-out;
      }
      .active {
        color: #fff;
      }
      div {
        width: calc(50% - 1px);
        height: 28px;
        position: absolute;
        left: 1px;
        top: 1px;
        border-radius: 30px;
        background-image: linear-gradient(45deg, #20d1e7, #41b2f5);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .teamName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    img {
      width: 13px;
      margin-right: 5px;
    }
  }
  .competitionName {
    padding-bottom: 5px;
  }
  .clock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #666666;
    padding-bottom: 5px;
    img {
      width: 13px;
      margin-right: 3px;
    }
  }
  .betData {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: inset 0px 0px 16px 1.7px rgba(0, 0, 0, 0.08);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 12px;
    font-size: 13px;
    p {
      color: #333333;
      &:first-child {
        color: #666666;
      }
    }
    .pending {
      color: #ff4e34;
    }
    .confirmed {
      color: #10bd2d;
    }

    div {
      width: 100%;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .betAmount {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    font-size: 13px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      color: #666666;
      p {
        &:nth-child(2) {
          color: #ff4e34;
        }
        &.betWin {
          color: #17ad14;
        }
      }
    }
    .amount {
      span {
        color: #42b5f8;
      }
      .pending {
        color: #ff4e34;
      }
      .confirmed {
        color: #10bd2d;
      }
    }
    .winLoss {
      span {
        color: #ff4e34;
      }
    }
  }
  .time {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: #999999;
  }
}

.unsettled {
  .unsettled-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f2f2f2;
    padding: 2px 0;
    position: fixed;
    width: 100%;
    top: 52px;
    left: 0;
    z-index: 10;
    p {
      flex: 1;
      padding: 5px 0;
      color: #999999;
      position: relative;
      transition: all 0.2s ease-in-out;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #44aff7;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: center;
        transition: all 0.2s ease-in-out;
      }
    }
    .active {
      color: #42b5f8;
      &::after {
        transform: scaleX(1);
      }
    }
  }
  .cashbets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 10;
    top: 86px;
    left: 0;
    width: 100%;
    background-color: #f3f8fe;
    p {
      font-size: 12px;
      color: #333333;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 18px;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      input:checked + .auto-round span {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
      }
      input:checked + .auto-round {
        background-color: #42b5f8;
      }
      .auto-round {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 30px;
        background-color: #c3c2c7;
        box-shadow: 0 0 1px #ebebeb;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        span {
          position: absolute;
          text-align: center;
          line-height: 22px;
          font-size: 11px;
          height: 14px;
          width: 14px;
          left: 2px;
          bottom: 2px;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 26px;
          z-index: 6;
          background-color: #fff;
        }
      }
    }
  }
  .gmt {
    font-size: 10px;
    color: #666666;
    padding: 10px 10px 15px;
  }
}

.betCard {
  width: 90%;
  margin: 0 auto 15px auto;
  padding: 10px;
  border-width: 1px;
  border-color: rgb(230, 230, 230);
  border-style: solid;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1.5px 1px 0px rgba(51, 51, 51, 0.2);
  color: #333333;
  font-size: 13px;
}

.settled {
  padding-top: 121px;
  padding-bottom: 50px;
  .dropdowns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 52px;
    left: 0;
    width: 100%;
    font-size: 12px;
    z-index: 20;
    .selected {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #1f92cc;
      color: #ffffff;
      position: relative;
      border-bottom: 1px solid #f3f8fe;
      font-family: 'PingFang Bold';
      p {
        pointer-events: none;
      }
      img {
        filter: brightness(0) invert(1);
        width: 5px;
        transform: rotate(-90deg);
        transition: all 0.2s ease-in-out;
        pointer-events: none;
      }
      .dropdown {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        z-index: -100;
        background-color: #1f92cc;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        p {
          background-color: #1f92cc;
          padding: 5px 10px;
          border-bottom: 1px solid #f3f8fe;
          pointer-events: none;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .selected-active {
      pointer-events: inherit;
      .dropdown {
        top: calc(100% + 1px);
        opacity: 1;
        z-index: 10;
        p {
          pointer-events: all;
        }
      }
      img {
        transform: rotate(90deg);
      }
    }
  }
  .settled-amount {
    background-color: #1f92cc;
    width: 100%;
    top: 90px;
    left: 0;
    position: fixed;
    padding: 10px;
    z-index: 10;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 11px;
        font-family: 'PingFang Bold';
        margin-bottom: 5px;
        color: #fff;
      }
    }
    .gmt {
      color: #f2f2f2;
      margin-top: 5px;
      p {
        font-family: 'PingFang Medium';
        margin-bottom: 0;
      }
    }
  }
  .betSummary {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    height: 62px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    &-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 13px;
        color: #333333;
        pointer-events: none;
        &:nth-child(1) {
          margin-bottom: 5px;
        }
        &:nth-child(2) {
          color: #999999;
        }
        .betAmount {
          color: #333333;
        }
        .betLoss {
          color: #ff4e34;
        }
        .betWin {
          color: #17ad14;
        }
      }
      img {
        pointer-events: none;
        width: 7px;
        transform: rotate(90deg);
        transition: all 0.3s ease-in-out;
      }
    }
    &-cards {
      padding-top: 10px;
      display: block;
      pointer-events: none;
      .betCard {
        width: 100%;
      }
    }
  }
  .betSummary-active {
    .betSummary-data {
      img {
        transform: rotate(-90deg);
      }
    }
    .betSummary-cards {
      pointer-events: auto;
    }
  }
}
