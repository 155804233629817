.deposit {
  padding-top: 121px;
  padding-bottom: 30px;
  max-width: 700px;
  margin: 0 auto;
  @media (min-width: 1200px) {
    padding-top: 100px;
  }
  .menu-sa-live-chat {
    button {
      filter: invert(30%);
    }
  }
  &-header {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 50px;
    background-image: url('../../img/common/header_bg.png');
    background-size: 100% 80px;
    background-position: center;
    box-shadow: 0.75px 1.25px 3px 0px rgba(51, 51, 51, 0.16);
    p {
      line-height: 50px;
      font-family: PingFang Medium;
      font-size: 18px;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 8px;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  &-tabs-container {
    width: 100%;
    max-width: calc(630px + 10%);
    position: fixed;
    top: 50px;
    left: 0px;
    z-index: 15;
    padding: 15px 0 10px;
    background-color: #f3f8fe;
    margin: 0 auto;
    @media (min-width: 630px) {
      left: calc((90% - 630px) / 2)
    }
    @media (min-width: 1200px) {
      width: 700px;
      top: 82px;
      left: calc((100vw - 714px) / 2);
      padding: 35px 0 20px;
    }
  }
  &-tabs {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-family: PingFang Medium;
      position: relative;
      width: calc(100% - 5px);
      height: 41px;
      line-height: 36px;
      text-align: center;
      z-index: 10;
      transition: all 0.2s ease;
      font-style: italic;
      color: #98a4b2;
      cursor: pointer;
      &::after {
        content: '';
        width: 100%;
        height: 38px;
        position: absolute;
        left: 0;
        top: -1px;
        z-index: -1;
        transition: all 0.2s ease;
        border-width: 1px;
        border-color: rgb(242, 242, 242);
        border-style: solid;
        border-radius: 8px;
        background-color: rgb(253, 255, 252);
        box-shadow: 0px 2px 0px 0px rgba(200, 206, 220, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
      }
      &:nth-child(1) {
        margin-right: 5px;
      }
      &:nth-child(2) {
        margin-left: 5px;
        &::before {
          content: '';
          width: 50px;
          height: 30px;
          position: absolute;
          right: -2px;
          top: -2px;
          background-image: url('../../img/deposit/blue-wrap.png');
          background-size: 100% auto;
          background-position: center;
          z-index: 10;
          transition: all 0.2s ease;
        }
      }
      &.active {
        color: #fff;
        font-family: PingFang Bold;
        &::after {
          opacity: 0;
        }
      }
    }
    div {
      width: calc(45% - 8px);
      height: 37px;
      position: absolute;
      left: 0;
      top: 15px;
      z-index: 5;
      transition: all 0.2s ease;
      border-radius: 8px;
      background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
      box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
      @media (min-width: 1200px) {
        top: 35px;
      }
    }
  }
  &-notice {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    border-width: 1px;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 4px;
    margin-bottom: 15px;
    background-color: rgb(214, 241, 255);
    box-shadow: 0px 2px 0px 0px rgba(186, 211, 230, 0.004), 0px 3px 2px 0px rgba(51, 51, 51, 0.13);
    p {
      font-family: PingFang Medium;
      color: #828e99;
      font-size: 12px;
    }
    @media (min-width: 1200px) {
      p {
        font-size: 14px;
      }
    }
  }
  &-methods {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px 16px;
    width: 90%;
    margin: 0 auto 15px;
    border-width: 1px;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 3px 0px rgba(200, 206, 220, 0.004), 0px 5px 8px 0px rgba(165, 184, 202, 0.28);
    .container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        height: 65px;
        width: 25%;
        max-width: 100px;
        padding: 7px;
        position: relative;
        cursor: pointer;
        img {
          max-width: 25px;
          max-height: 25px;
          margin-bottom: 8px;
        }
        p {
          font-family: PingFang Medium;
          font-size: 10px;
          color: #3b4e73;
          white-space: nowrap;
          @media (min-width: 1200px) {
            font-size: 14px;
          }
        }
      }
      .active {
        border-width: 1px;
        border-color: rgb(66, 181, 248);
        border-style: solid;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 2px 0.2em 0.01em rgba(66, 181, 248, 0.99);
        &:nth-child(2) {
          &::after {
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            right: -2px;
            top: -2px;
            background-image: url('../../img/deposit/red-wrap.png');
            background-size: 100% auto;
            background-position: center;
            z-index: 10;
            transition: all 0.2s ease;
          }
        }
      }
    }
  }
  &-amount {
    padding: 15px 10px 5px;
    width: 90%;
    margin: 0 auto 15px;
    border-width: 1px;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 3px 0px rgba(200, 206, 220, 0.004), 0px 5px 8px 0px rgba(165, 184, 202, 0.28);
    position: relative;
    .form-title {
      font-family: PingFang Bold;
      color: #3b4e73;
      font-size: 14px;
      margin-bottom: 15px;
      @media (min-width: 1200px) {
        font-size: 15px;
      }
    }
    .maintenance {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 20;
      background-color: rgba($color: #ccc, $alpha: 0.2);
      backdrop-filter: blur(10px);
      border-radius: 4px;
      p {
        font-family: Trends;
        color: #cccccc;
        font-weight: bolder;
        margin: 5px 0;
      }
      img {
        width: 60px;
      }
    }
    .select {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      select {
        border-radius: 2px;
        background-color: rgb(247, 247, 247);
        box-shadow: inset 0px 0px 3.5px 0.5px rgba(51, 51, 51, 0.08);
        width: 100%;
        padding: 7px 10px;
        border: none;
        outline: none;
        font-family: PingFang Medium;
        font-size: 14px;
        color: #cccccc;
        option {
          opacity: 0.5;
        }
      }
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 34px;
        pointer-events: none;
        border-radius: 2px;
        background-color: rgb(247, 247, 247);
        box-shadow: inset 0px 0px 3.5px 0.5px rgba(51, 51, 51, 0.08);
        width: 100%;
        padding: 7px 10px;
        color: #cccccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 7px;
          transform: rotate(180deg);
        }
        p {
          font-family: PingFang Medium;
          font-size: 14px;
          opacity: 0.5;
          margin: 0;
        }
      }
    }
    .deposit-btn {
      width: calc(100% + 12px);
      margin: 0 -6px;
      cursor: pointer;
    }
    .btn {
      margin: 0 -5px;
      button {
        font-family: Trends;
        font-style: italic;
        font-weight: bolder;
        color: #42b5f8;
        font-size: 12px;
        width: calc(25% - 10px);
        padding: 7px 0;
        margin: 0 5px 12px;
        border-radius: 4px;
        background-color: rgb(253, 255, 252);
        border: 1px solid #42b5f8;
        letter-spacing: 2px;
        white-space: nowrap;
        @media (min-width: 1200px) {
          font-size: 14px;
        }
        &.active {
          color: #fff;
          background-color: #42b5f8;
        }
        &.usdt {
          &::before {
            content: '';
            background-image: url('../../img/deposit/usdt.png');
            background-size: 100% auto;
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-bottom: -3px;
            margin-right: 5px;
          }
        }
      }
      &.bank-list {
        button {
          letter-spacing: 1px;
        }
      }
    }
    .btn-5 {
      button {
        width: calc(20% - 10px);
      }
    }

    .input {
      .error-message {
        color: #f25562;
        margin-top: -5px;
      }
      p {
        font-family: PingFang Bold;
        color: #3b4e73;
        font-size: 14px;
        margin-bottom: 7px;
      }
      input {
        width: 100%;
        padding: 7px 10px;
        font-family: PingFang Medium;
        font-size: 14px;
        border-radius: 2px;
        background-color: rgb(247, 247, 247);
        box-shadow: inset 0px 0px 3.5px 0.5px rgba(51, 51, 51, 0.08);
        color: #cccccc;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        &.error {
          box-shadow: inset 0px 0px 3.5px 0.5px red;
        }
        &::placeholder {
          opacity: 0.5;
        }
      }
      span {
        display: block;
        font-size: 9px;
        color: #666666;
        font-family: PingFang Medium;
        margin-top: -5px;
        margin-bottom: 10px;
      }
    }
  }
  .customer {
    width: 90%;
    text-align: center;
    margin: 20px auto;
    font-size: 16px;
    color: #828e99;
    font-family: PingFang Medium;
    button {
      font-size: 16px;
      color: #42b5f8;
      text-decoration: underline;
    }
  }
  .reminder {
    font-family: PingFang Medium;
    font-size: 12px;
    color: #f25562;
    margin-bottom: 12px;
    @media (min-width: 1200px) {
      font-size: 14px;
    }
  }
  &-name {
    padding: 15px 10px;
    width: 90%;
    margin: 0 auto 15px;
    border-width: 1px;
    border-color: rgb(242, 242, 242);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 3px 0px rgba(200, 206, 220, 0.004), 0px 5px 8px 0px rgba(165, 184, 202, 0.28);
    p {
      font-family: PingFang Bold;
      color: #3b4e73;
      font-size: 14px;
      margin-bottom: 7px;
    }
    input {
      width: 100%;
      padding: 7px 10px 7px 0;
      font-family: PingFang Medium;
      font-size: 14px;
      border-bottom: 1px solid #cccccc;
      color: #cccccc;
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
  &-btn {
    width: calc(90%);
    margin: 0 auto 15px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    background-image: linear-gradient(0deg, rgb(30, 211, 230) 0%, rgb(49, 193, 239) 46%, rgb(68, 175, 247) 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    button {
      font-family: PingFang Bold;
      font-style: italic;
      color: #ffffff;
      font-size: 16px;
    }
  }
  &-banner {
    width: calc(90% + 4px);
    margin: 0 auto 8px;
    img {
      width: 100%;
    }
  }
  &-instruction {
    width: 90%;
    margin: 0 auto;
    color: #828e99;
    font-family: PingFang Medium;
    font-size: 12px;
    margin-bottom: 15px;
    span {
      font-size: 14px;
      color: #f25562;
    }
    @media (min-width: 1200px) {
      font-size: 14px;
      span {
        font-size: 15px;
      }
    }
  }
}

.add-phone {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 20;
  .success {
    color: #5bb374;
  }
  .failed {
    color: #f15151;
  }
  button {
    font-family: PingFang Bold;
    font-style: italic;
    color: #fff;
    background-image: linear-gradient(0deg, rgb(30, 211, 230) 0%, rgb(49, 193, 239) 46%, rgb(68, 175, 247) 100%);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 6px 10px;
    border-radius: 4px;
  }
  .popup {
    max-width: 350px;
    padding: 15px 15px 25px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 0px 0px rgba(200, 206, 220, 0.004), 0px 6px 8px 0px rgba(51, 51, 51, 0.14);
    color: #333333;
    font-family: PingFang Medium;
  }
  .title {
    text-align: center;
    font-family: PingFang Bold;
    margin-bottom: 10px;
  }
  .input {
    width: 100%;
    padding: 8px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    label {
      display: inline-block;
      width: 80px;
    }
    input {
      display: inline-block;
      width: calc(100% - 80px);
      font-size: 16px;
      color: #cccccc;
      &::placeholder {
        opacity: 0.3;
      }
    }
  }
  .verification {
    display: flex;
    align-items: center;
    input {
      width: calc(100% - 80px - 120px);
    }
    button {
      display: block;
      margin-left: auto;
      font-size: 13px;
      background-image: linear-gradient(0deg, rgb(25, 198, 219) 0%, rgb(55, 166, 244) 80%, rgb(29, 213, 230) 99%);
      box-shadow: 0px 2px 0px 0px #0077a8, 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
      &:disabled {
        background-image: none;
        background-color: #ccc;
        box-shadow: 0px 2px 0px 0px rgba(168, 168, 168, 0.004), 0px 3px 1px 0px rgba(51, 51, 51, 0.32);
      }
    }
  }
  .note {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      font-size: 15px;
      text-shadow: 1px 1px #aaa;
      padding: 7px 0;
      width: calc((100% / 2) - 5px);
      &:disabled {
        opacity: 0.6;
      }
    }
  }
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -10;
  }
}

.deposit-tips {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 20;
  button {
    font-family: PingFang Bold;
    font-style: italic;
    color: #fff;
    background-image: linear-gradient(0deg, rgb(30, 211, 230) 0%, rgb(49, 193, 239) 46%, rgb(68, 175, 247) 100%);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 6px 10px;
    border-radius: 4px;
  }
  .popup {
    max-width: 350px;
    padding: 18px 15px 25px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 0px 0px rgba(200, 206, 220, 0.004), 0px 6px 8px 0px rgba(51, 51, 51, 0.14);
    color: #333333;
    font-family: PingFang Medium;
  }
  .title {
    text-align: center;
    font-family: PingFang Bold;
    margin-bottom: 18px;
  }
  .desc {
    margin-bottom: 23px;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      font-size: 15px;
      text-shadow: 1px 1px #aaa;
      padding: 7px 0;
      width: calc((100% / 2) - 5px);
      &:nth-child(1) {
        opacity: 0.6;
      }
    }
  }
}
