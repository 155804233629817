.modal {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  .body {
    width: 100%;
    max-width: 300px;
    text-align: center;
    padding: 30px 15px 25px;
    background-color: #fff;
    border-radius: 6px;
    p {
      font-family: PingFang Bold;
      font-size: 18px;
      margin-bottom: 35px;
    }
    button {
      font-size: 15px;
      text-shadow: 1px 1px #aaa;
      padding: 7px 0;
      width: calc((100% / 2) - 5px);
      font-family: PingFang Bold;
      font-style: italic;
      color: #fff;
      background-image: linear-gradient(0deg, rgb(30, 211, 230) 0%, rgb(49, 193, 239) 46%, rgb(68, 175, 247) 100%);
      box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.16);
      padding: 10px;
      border-radius: 4px;
    }
  }
}
