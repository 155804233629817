.ticker-over-sa {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  .ticker-over-sa-cont {
    max-width: 413px;
    width: 100%;
    height: 218px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 425px) {
      width: 330px;
    }
    @media screen and (max-width: 320px) {
      width: 300px;
    }

    .ticker-over-sa--header {
      background: #e9edf7;
      padding: 15.5px 0;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      position: relative;
      text-align: center;
      h3 {
        font-size: 20px;
        background: linear-gradient(-45deg, #04a0f9 72%, #08b1f9);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: auto;
        font-family: 'PingFang Bold';
      }
    }

    .ticker-over-sa--body {
      background: #fff;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 20px;

      .ticker-text {
        color: #39404d;
        font-size: 14px;
        margin-bottom: 35px;
        text-align: center;
        font-family: 'PingFang Medium';
      }
      .ticker-button {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          display: inline-block;
          font-size: 17px;
          color: #fff;
          border-radius: 5px;
          width: 140px;
          height: 50px;
          text-align: center;
          margin: 0 5px;
          @media screen and (max-width: 425px) {
            width: 130px;
          }
          @media screen and (max-width: 320px) {
            width: 110px;
          }

          &.ticker-close {
            background: url('../../img/home/cancel.png');
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
            line-height: 48px;
          }
          &.ticker-view {
            width: 142px;
            height: 52px;
            padding-left: 2px;
            padding-bottom: 2px;
            line-height: 50px;
            background: url('../../img/home/login.png');
            background-size: 100% auto;
            background-position-y: -1px;
            background-repeat: no-repeat;
            @media screen and (max-width: 425px) {
              width: 132px;
            }
            @media screen and (max-width: 320px) {
              width: 112px;
            }
          }
        }
      }
    }
  }

  &.error {
    .ticker-button {
      text-align: center;
    }
  }

  &.wap {
    .ticker-over-sa--header {
      display: none;
    }
    .ticker-over-sa--body {
      position: relative;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding-top: 50px;
      .xclose {
        width: 28px;
        height: 28px;
        border-radius: 6px;
        display: inline-block;
        position: absolute;
        top: 6px;
        right: 10px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 3px;
          background-color: black;
          border-radius: 2px;
          top: 16px;
        }
        &:before {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          transform: rotate(45deg);
          left: 2px;
        }
        &:after {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          transform: rotate(-45deg);
          right: 2px;
        }
      }
    }
  }
}

// .ticker-over-sa {
// 	@include overlay(
// 		$background-color: rgba(#8e9bae, .5),
// 	);
// 	position: fixed;
// 	overflow-y: auto;
// 	overflow-x: hidden;
// 	&--sublayer {
// 		width: 90%;
// 	}
// 	&--layer {
// 		padding: 20px 30px 30px;
// 		background: rgba(#fff, .95);
// 		position: relative;
// 		@include radius(5px);
// 		.close {
// 			@include size(26px, 26px);
// 			@include position(absolute, $z-index: 1000, $top: 20px, $right: 20px);
// 		}
// 	}
// 	h1 {
// 		text-align: center;
// 		margin: 0 0 10px;
// 		color: #c9dadf;
// 		@include font(22px, 600);
// 	}
// 	h2, h2 p, h2 .text-shadow {
// 		font-size: 42px;
// 	}
// 	h2 {
// 		p {
// 			margin: 0;
// 		}
// 	}
// 	.nt-list--item {
// 		margin: 24px 0 0;
// 		h4 {
// 			color: #212533;
// 			white-space: normal;
// 			@include font(18px, 500);
// 		}
// 		p {
// 			color: #55687d;
// 			margin: 10px 0 0;
// 			@include font(16px, 400);
// 		}
// 	}
// }
