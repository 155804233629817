.bet-history {
  padding: 101px 0 50px 0;
  &-header {
    width: 100%;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: 0.75px 1.25px 3px 0px rgba(51, 51, 51, 0.16);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }
  &-title {
    text-align: center;
    position: relative;
    height: 50px;
    p {
      line-height: 50px;
      font-family: 'PingFang Medium';
      font-size: 18px;
      color: #3e5074;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 8px;
    }
  }
  &-selectors {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 15px;
    .bet-history-selector {
      margin-right: 10px;
      width: 100px;
      position: relative;
      padding: 7px 10px;
      background-color: #f5f5f5;
      border-radius: 32px;
      p {
        font-family: 'PingFang Medium';
        font-size: 12px;
        color: #333333;
      }
      span {
        position: absolute;
        top: calc(50% - 2px);
        right: 10px;
        border: 4px solid transparent;
        border-top: 5px solid #333333;
      }
      .active {
        border: 4px solid transparent;
        border-bottom: 5px solid #333333;
        top: calc(50% - 7px);
      }
    }
  }
  &-dropdown {
    position: fixed;
    top: 40px;
    width: 100%;
    z-index: -10;
    transition: all 0.2s ease-in-out;
    .dropdown-content {
      background-color: #fafafa;
      padding: 5px;
      width: 100%;
      p {
        font-family: 'PingFang Medium';
        color: #999999;
        font-size: 9px;
        margin-left: 5px;
      }
      button {
        font-family: 'PingFang Medium';
        color: #999999;
        width: calc(20% - 10px);
        margin: 5px;
        padding: 5px 0;
        font-size: 11px;
        border-width: 1px;
        border-color: rgb(230, 230, 230);
        border-style: solid;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
      }
      .active {
        background-image: linear-gradient(90deg, rgb(68, 175, 247) 0%, rgb(49, 193, 239) 54%, rgb(30, 211, 230) 100%);
        background-image: -moz-linear-gradient(
          90deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        background-image: -webkit-linear-gradient(
          90deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        background-image: -ms-linear-gradient(
          90deg,
          rgb(68, 175, 247) 0%,
          rgb(49, 193, 239) 54%,
          rgb(30, 211, 230) 100%
        );
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
        border: none;
        color: #ffffff;
        padding: 6px 1px;
      }
    }
    .dropdown-bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: -1;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
  }
  .custom-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 10px 10px;
    text-align: center;
    font-family: 'PingFang Bold';
    .date {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 15px;
      span {
        margin: 0 5px;
      }
      .select-date {
        position: relative;
        width: 80px;
        height: 30px;
        select {
          width: 100%;
          opacity: 0;
        }
        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 80px;
          height: 100%;
          pointer-events: none;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: inset 0px 0px 1px 0.5px rgba(51, 51, 51, 0.08);
          color: #000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 10px;
          img {
            width: 5px;
            transform: rotate(180deg);
            -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
          }
          p {
            font-family: 'PingFang Medium';
            margin: 0;
          }
        }
      }
    }
  }
  &-dropdown-active {
    top: 101px;
    z-index: 10;
    .dropdown-bg {
      opacity: 1;
    }
  }
  &-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    p {
      font-family: 'PingFang Medium';
      font-size: 13px;
      color: #333333;
    }
  }
  &-date {
    padding: 0 10px 10px;
    p {
      font-family: 'PingFang Bold';
      color: #333333;
    }
  }
  &-card {
    width: calc(100% - 20px);
    margin: 0 auto 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.16);
    color: #333333;
    .game {
      font-family: 'PingFang Bold';
      padding: 5px 0;
      border-bottom: 1px solid #eeee;
    }
    .bet-data {
      padding: 7px 0;
      .gameType {
        font-family: 'PingFang Bold';
        font-size: 14px;
        padding-left: 5px;
        position: relative;
        margin-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 3px;
          height: calc(100% - 4px);
          width: 2px;
          background: linear-gradient(180deg, rgb(68, 175, 247) 0%, rgb(49, 193, 239) 54%, rgb(30, 211, 230) 100%);
        }
      }
    }
    .betType {
      padding-left: 5px;
      margin-bottom: 5px;
      font-family: 'PingFang Medium';
      font-size: 13px;
      span {
        color: #51b4f9;
      }
    }
    .result {
      padding-left: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'PingFang Medium';
      font-size: 13px;
    }
    .date {
      padding: 5px 0;
      border-top: 1px solid #eeee;
      color: #999999;
      font-family: 'PingFang Medium';
      font-size: 11px;
      span {
        font-family: 'PingFang Bold';
      }
    }
  }
}
