.members-desktop {
  .card-head {
    background-image: linear-gradient(#c3cfe2, #f5f7fa);
    padding: 10px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    .card-title {
      color: #4796ff;
      font-size: 14px;
      span {
        width: 20%;
        display: inline-block;
        padding: 2px;
      }
    }
  }
  .card-body {
    height: 650px;
    overflow-y: auto;
    .no-transactions {
      height: 416px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #95969a;
      padding-top: 66px;
      .image-box {
        background: url('../../../../assets/img/profile/svg/open-box.svg') no-repeat center
          center/contain;
        width: 158px;
        height: 137px;
        display: inline-block;
        margin-bottom: 23px;
      }
      span {
        font-size: 12px;
      }
    }

    .card-item {
      width: 100%;
      box-sizing: border-box;
      padding: 19px 20px;
      border-bottom: 1px solid #f1efef;
      border-left: 1px solid #f1efef;
      border-right: 1px solid #f1efef;
      background: #fff;
      &:last-child {
        margin: 0;
      }
      span {
        width: 20%;
        display: inline-block;
        @include font(14px, 500, 1.4);
        &:first-child {
          color: #212533;
        }
        &:nth-child(5) {
          button {
            padding: 5px 10px;
            border-radius: 3px;
            color: #fff;
            background-image: linear-gradient(-46deg, #04a0f9, #08b1f9);
            &:first-child {
              margin-right: 10px;
            }
          }
        }
        &:last-child {
          color: #95969a;
        }
      }

      .empty {
        text-align: left;
        padding-top: 20px;
        font-size: 12px;
        color: #000;
      }

      .deposits {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        font-size: 12px;
        color: #000;
        .amount {
          color: #ff934c;
          span {
            font-size: 12px;
            color: #fc686f;
          }
        }
        .status {
          color: #5ccbef;
          &.success {
            color: #5bb374;
          }
          &.failure {
            color: #f15151;
          }
        }
      }
      .withdraw {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        font-size: 12px;
        color: #000;
        .amount {
          color: #ff934c;
          span {
            font-size: 12px;
            color: #fc686f;
          }
        }
        .status {
          color: #5ccbef;
          &.success {
            color: #5bb374;
          }
          &.failure {
            color: #f15151;
          }
          &.unreviewed {
            color: #ffcc00;
          }
          &.withdrawing {
            color: #5ccbef;
          }
          &.remittance {
            color: #5ccbef;
          }
        }
      }
    }
  }
}
