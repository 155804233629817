@font-face {
  font-family: 'BenmoJinhei';
  src: url('../../fonts/BenmoJinhei.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Metropolis';
  src: url('../../fonts/Metropolis-Black.otf');
}

@font-face {
  font-family: 'BebasNeue';
  src: url('../../fonts/BebasNeue-Regular.ttf');
}
