.messages-wrap {
  &.mobile {
    .content-inner {
      padding: 0;
    }
    .load-spin {
      position: fixed !important;
      z-index: 1000 !important;
    }
    .messages-list--item {
      .messages-list--item-content {
        display: none;
      }
      &.opened {
        .messages-list--item-content {
          display: block;
        }
      }
    }
    .messages-list {
      padding: 35px 12px;
      &--item {
        background: #fff;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &.opened {
          margin: 0;
        }
        .messages-list--item-wrap {
          border: 0;
          padding: 18px 30px;
          position: relative;
          .subject,
          .timestamp {
            color: #39404d;
            font-size: 14px;
            font-weight: normal;
          }
          .m-status {
            position: absolute;
            right: 8px;
            top: 8px;
            p {
              color: #a0a1a1;
              font-size: 10px;
              border-radius: 5px;
              padding: 2.5px 12px;
              background: linear-gradient(135deg, #d2cec7, #dddcd7, #e1deda);
              &.unread {
                color: #51afe4;
                background: linear-gradient(135deg, #c2e9fb, #a1c4fd);
              }
            }
          }
        }
        .messages-list--item-content {
          padding: 18px 30px 42px;
          P {
            font-size: 14px;
            color: #39404d;
          }
        }
      }

      .no-transactions {
        height: 416px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #95969a;
        padding-top: 66px;
        .image-box {
          background: url('../../img/inbox/mail-box.png') no-repeat center center/contain;
          width: 140px;
          height: 140px;
          display: inline-block;
          margin-bottom: 23px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
