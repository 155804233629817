.nativedata {
  padding-bottom: 70px;
  .nativedata-header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 19;
    background-color: #f3f8fe;
  }
}

.competition-field {
  width: 100%;
  position: relative;
  .field-img {
    width: 100%;
  }
  .back {
    position: absolute;
    top: 8px;
    left: 0px;
    z-index: 31;
    padding: 10px 15px;
    img {
      width: 8px;
    }
  }
  .competition-selectgame {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    p {
      color: #fff;
      font-size: 17px;
      white-space: nowrap;
      font-family: 'PingFang Medium';
      max-width: 75vw;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        border: 6px solid transparent;
        border-top: 6px solid #fff;
        margin-bottom: -8px;
        margin-left: 5px;
      }
    }
  }
  .competition-game {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    top: 50%;
    right: 5%;
    position: absolute;
    color: #fff;
    transform: translateY(-50%);
    div {
      flex: 1;
      text-align: center;
    }
    .competition-time {
      margin: auto 10px;
      p {
        color: #fefefe;
      }
      .score {
        font-family: 'PingFang Bold';
        font-size: 20px;
      }
      .time {
        font-family: 'PingFang Regular';
        opacity: 0.7;
      }
    }
    .competition-team {
      font-size: 12px;
      font-family: 'PingFang Medium';
      color: #fefefe;
      img {
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
  .competition-bottom-menu {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 13px;
      background-color: rgba($color: #3d3d3d, $alpha: 0.7);
      border-radius: 24px;
      margin: 0 8px;
      img {
        width: 18px;
        max-height: 18px;
        margin-right: 5px;
      }
      p {
        font-family: 'PingFang Medium';
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.competition-live {
  width: 100vw;
  position: relative;
  background-color: #fff;
  video {
    border-radius: 0;
  }
  .close {
    width: 25px;
    right: 10px;
    top: 10px;
  }
  .fullscreen {
    width: 27px;
    right: 10px;
    bottom: 15px;
  }
  .mute {
    width: 27px;
    left: 10px;
    bottom: 15px;
  }
  #live-video {
    display: flex;
  }
  &.flash-live {
    display: flex;
    justify-content: center;
    align-items: center;
    iframe {
      height: 262px;
      @media (max-width: 375px) {
        height: 242px;
      }
    }
  }
}

.competition-field-disable {
  .back {
    z-index: 10;
  }
  .competition-selectgame {
    z-index: 10;
  }
}

.competition-tabs-container {
  position: relative;
  margin: 10px;
  border-radius: 8px;
  background-image: url('../../img/native-data/header_tabs_bg.png');
  background-size: 100% 47px;
  margin-top: 3px;
  .minimize {
    position: absolute;
    width: 22px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      padding: 0 5px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.competition-tabs {
  width: calc(100% - 30px);
  padding: 8px 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  div {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .active {
      background-color: rgb(255, 255, 255);
      box-shadow: inset 0px 0px 2.5px 0.24px rgba(4, 4, 4, 0.56);
      color: #42b5f8;
      font-family: 'Trends';
    }
    p {
      padding: 5px 15px;
      font-size: 14px;
      border-radius: 41px;
      color: #fff;
      transition: all 0.2s ease-in-out;
      font-family: 'PingFang Medium';
    }
  }
}

.nativedata-table {
  width: calc(100% - 20px);
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-color: rgb(242, 242, 242);
  border-style: solid;
  box-shadow: 0px 2px 6px 0px rgba(51, 51, 51, 0.16);
  .table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
      font-weight: bolder;
      font-family: 'PingFang Bold';
      color: #384a6e;
      pointer-events: none;
    }
    img {
      position: absolute;
      width: 25px;
      right: -5px;
      top: 2px;
      padding: 5px;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
    }
  }
  .table-item {
    p {
      pointer-events: none;
    }
  }
  .c3 {
    .table-item {
      width: calc((100% / 3) - 7px);
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
  .c2 {
    .table-item {
      width: calc((100% / 2) - 5px);
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
  }
  .table-body {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #b0c9cf;
    padding-top: 10px;
    margin-top: 10px;
    .table-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 8px 0;
      border-radius: 8px;
      background-color: #f5f8ff;
      p {
        &:first-child {
          font-size: 12px;
          color: #8f9eb2;
          font-family: 'PingFang Medium';
        }
        &:last-child {
          font-size: 13px;
          font-family: 'PingFang Bold';
          color: #384a6e;
          position: relative;
          transition: all 0.2s ease-in-out;
          &::after {
            content: '';
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
            position: absolute;
            top: 50%;
            left: 100%;
            border: 5px solid transparent;
          }
          &.increase {
            color: #1ed68e;
            &::after {
              border-left-color: #1ed68e;
              transform: rotate(-45deg);
              top: -5px;
            }
          }
          &.decrease {
            color: #ff4e34;
            &::after {
              border-left-color: #ff4e34;
              transform: rotate(45deg);
              top: 80%;
            }
          }
        }
      }
    }
  }
}

.chat-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  input {
    background-color: #ecfcff;
    padding: 8px 15px;
    width: 100%;
    border-radius: 8px;
    margin-right: 10px;
  }
  img {
    width: 60px;
    margin-bottom: -3px;
  }
}

.selectgame {
  position: fixed;
  left: 0;
  z-index: 50;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.95);
  transition: all 0.3s ease-in-out;
  top: -100%;
  opacity: 0;
  pointer-events: none;
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba($color: #000000, $alpha: 0.7);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
  }
  .selectgame-body {
    padding-bottom: 80px;
  }
  .selectgame-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        font-size: 17px;
        white-space: nowrap;
        font-family: 'PingFang Medium';
        max-width: 75vw;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      div {
        border: 6px solid transparent;
        border-bottom: 6px solid #fff;
        margin-top: -6px;
        margin-left: 5px;
      }
    }
  }
  .selectgame-back {
    position: absolute;
    top: 20px;
    left: 10px;
    width: 8px;
  }
  .selectgame-game {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    width: 95%;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 23px;
    margin-bottom: 15px;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    font-family: 'PingFang Medium';
    div {
      flex: 1;
      text-align: center;
    }
    .selectgame-time {
      margin: auto 10px;
    }
    .selectgame-team {
      p {
        font-size: 13px;
      }
      img {
        max-width: 50px;
        max-height: 50px;
        margin-bottom: 5px;
      }
    }
  }
  .active {
    border: 2px solid #8fe5ee;
  }
}
