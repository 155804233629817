@font-face {
  font-family: 'Sports Count';
  src: url('../../fonts/sports-count.TTF');
}

@font-face {
  font-family: 'PingFang Light';
  src: url('../../fonts/PingFang\ Light.ttf');
}

@font-face {
  font-family: 'PingFang Regular';
  src: url('../../fonts/PingFang\ Regular.ttf');
}

@font-face {
  font-family: 'PingFang Medium';
  src: url('../../fonts/PingFang\ Medium.ttf');
}

@font-face {
  font-family: 'PingFang Bold';
  src: url('../../fonts/PingFang\ Bold.ttf');
}

@font-face {
  font-family: 'Trends';
  src: url('../../fonts/Trends.TTF');
}

@font-face {
  font-family: 'Century Gothic';
  src: url('../../fonts/Century\ Gothic\ 400.ttf');
}

@font-face {
  font-family: 'Lcd Mono';
  src: url('../../fonts/LCD_mono.ttf');
}

@font-face {
  font-family: 'Banner';
  src: url('../../fonts/banner.ttf');
}
