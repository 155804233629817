.promo-history-wrap {
  .promo-history-wrap-inner {
    padding-bottom: 100px;
  }

  .promo-history-card-head {
    background-image: linear-gradient(#c3cfe2, #f5f7fa);
    padding: 10px 19px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    .promo-history-card-title {
      color: #4796ff;
      font-size: 14px;
      span {
        border: 1px solid #4796ff;
        width: 103px;
        display: block;
        text-align: center;
        border-radius: 13px;
        padding: 2px;
      }
      i {
        background: url('../../img/common/refresh.svg') no-repeat center center/contain;
        width: 21px;
        height: 21px;
        display: inline-block;
        position: absolute;
        right: 19px;
        top: 50%;
        transform: translateY(-50%);
        &.reload {
          animation: rotation 2s infinite linear;
          -webkit-animation: rotation 2s infinite linear;
        }
      }
      @keyframes rotation {
        from {
          transform: translateY(-50%) rotate(0deg);
        }
        to {
          transform: translateY(-50%) rotate(359deg);
        }
      }
    }
  }
  .promo-history-card-body {
    .no-transactions {
      height: 416px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #95969a;
      padding-top: 66px;
      .image-box {
        background: url('../../img/profile/svg/open-box.svg') no-repeat center center/contain;
        width: 158px;
        height: 137px;
        display: inline-block;
        margin-bottom: 23px;
      }
      span {
        font-size: 12px;
      }
    }
    .promo-history-card-item {
      padding: 19px 33px;
      border-bottom: 1px solid #f1efef;
      border-left: 1px solid #f1efef;
      border-right: 1px solid #f1efef;
      background: #fff;
      .cl-item {
        display: inline-block;
        width: 50%;
        font-size: 12px;
        &.promo-history-card-text {
          text-align: left;
          color: #95969a;
        }
        &.promo-history-card-value {
          float: right;
          min-width: 55px;
          width: auto;
          text-align: left;
          p {
            &.cl-card-amount {
              color: #ff934c;
              span {
                color: #fc686f;
              }
            }
            &.cl-card-status {
              &.success {
                color: #5bb374;
              }
              &.failure {
                color: #f15151;
              }
            }
          }
        }
      }
    }
  }
}
