.login-wrap {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: url(../../img/login/bgnew.png) no-repeat center center/cover;
  .login-form {
    margin: auto 0;
    &-wrap {
      padding: 20px 0;
      height: 100%;
    }
    &-outer {
      .load-spin {
        @include radius(10px);
      }
    }
    &-outer-bottom {
      width: 442px;
      .load-spin {
        @include radius(10px);
      }
      overflow: hidden;
      background-color: rgba(#0c7cd3, 0.33);
      padding: 0 40px;
      @include radius(10px);
      @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
      margin-bottom: 30px;
    }
    &-inner {
      height: 592px;
    }
  }
  .login-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
  }

  .divider {
    text-align: center;
    position: relative;
    color: #a3cff8;
    font-size: 12px;
    margin-bottom: 10px;
    width: 410px;
    &:before {
      position: absolute;
      content: "";
      background: linear-gradient(
        90deg,
        rgba(82, 164, 235, 0.2637) 11%,
        rgba(82, 164, 235, 0.5158) 24%,
        rgba(105, 170, 232, 1) 57%,
        rgba(105, 170, 232, 0.7119) 89%,
        rgba(105, 170, 232, 0.32217) 95%
      );
      width: 165px;
      height: 1px;
      top: 50%;
      left: 0;
    }
    &:after {
      position: absolute;
      content: "";
      background: linear-gradient(
        90deg,
        rgba(105, 170, 232, 0.34217) 5%,
        rgba(105, 170, 232, 0.7119) 11%,
        rgba(105, 170, 232, 1) 43%,
        rgba(82, 164, 235, 0.5158) 68%,
        rgba(82, 164, 235, 0.2637) 89%
      );
      width: 165px;
      height: 1px;
      top: 50%;
      right: 0;
    }
    @media screen and (max-width: 375px) {
      &::before,
      &:after {
        width: 100px;
      }
    }
  }

  .sub-logo {
    background: url(../../img/login/FC-Augsburg_logo.svg) no-repeat center
      center/contain;
    // margin: 12px auto 5px;
    @include size(25px, 32px);
  }
  .sub-text {
    color: #a3cff8;
    font-size: 12px;
    text-align: center;
    margin: 5px 0 30px;
  }

  .ui-tabs {
    background: url(../../img/login/tab.svg) no-repeat center center/contain;
    height: 54px;
    box-shadow: none;
    margin: 0 0 20px;
    &:after {
      border: none;
    }
    .ui-tabs--tab {
      color: #b5dbf8;
      font-weight: normal;
      font-size: 14px;
      &.active {
        cursor: default;
        padding: 11px;
        padding-left: 85px;
        color: #fff;
        font-size: 22px;
      }
      &.nonactive {
        width: 25%;
        text-align: right;
        padding: 5px;
      }
    }
  }

  .logo {
    font-size: 27px;
    color: #fff;
    margin: 0 auto 20px auto;
    padding-left: 61px;
    background-size: contain;
    width: max-content;
    position: relative;
    &::before {
      content: '';
      background: url(../../img/login/ubet_white_text_logo.png) no-repeat center center/cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      width: 51px;
      height: 51px;
    }
  }

  .form {
    text-align: center;
    &-inner-content {
      padding-bottom: 40px;

      p {
        text-align: left;
        position: absolute;
      }
      &.signup {
        padding-bottom: 30px;

        p {
          width: 100%;
        }
      }
    }
    .restore-wrap {
      margin: 17px 0 0;
    }
    button.submit {
      outline: none;
      width: 180px;
      font-weight: 400;
      padding: 11px;
      @include radius(5px);
      @include gradient2(100deg, #e1deda, #dddcd7 45%, #d2cec7 100%);
      color: #bcb8b8;
      font-size: 19px;
    }
    button.submit.active {
      color: #fff;
      @include gradient2(0deg, #a1c4fd, #c2e9fb 100%);
    }
    a {
      line-height: 17px;
      color: #fff;
      font-size: 16px;
    }

    .form-field {
      margin: 0 0 20px;
    }
    label {
      display: none;
    }

    .form-field--input-wrap {
      position: relative;
      input {
        border-top: none;
        border-right: none;
        border-left: none;
        padding-left: 50px;
        padding-right: 38px;
        border-radius: 0;
        background: none;
        color: #fff;
        font-size: 15px;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
        &:-webkit-autofill {
          // background: red;
          -webkit-text-fill-color: #fff;
          -webkit-text-fill-color: none;
          -webkit-box-shadow: 0 0 0px 1000px #fff0 inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }
      input[type="password"] {
        font: 15px Verdana, sans-serif !important;
        letter-spacing: 1px;
        height: 44px;
      }
      span {
        content: "";
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-color: #fff;
        filter: brightness(0) invert(1);
      }
      span.username {
        background: url(../../img/login/user.svg) no-repeat center center/contain;
        // mask-image: url(../../img/login/user.svg);
      }
      span.password,
      span.passwordok {
        background: url(../../img/login/password.svg) no-repeat center
          center/contain;
      }

      span.eye {
        cursor: pointer;
        background: url(../../img/login/show-password.svg) no-repeat center
          center/contain;
        right: 5px;
        left: unset;
        opacity: 0.5;
        padding: 9px;
        width: 19px;
        height: 11px;
        &.vision {
          opacity: 1;
        }
      }

      span.telephone {
        background: url(../../img/login/cellphone.svg) no-repeat center
          center/contain;
      }
      span.agentName {
        background: url(../../img/login/referral.svg) no-repeat center
          center/contain;
      }
    }
  }

  .auth-forms {
    display: flex;
    align-items: flex-start;
    padding-top: 70px;
    @include transition(transform 0.2s ease);
    .form {
      min-width: 100%;
      opacity: 0;
      pointer-events: none;
      @include transition(opacity 0.2s ease);
    }
    &.tab--signin {
      height: 342px;
      .form--signin {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &.tab--signup {
      height: 492px;
      @include transform(translateX(-100%));
      .form--signup {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
