.promotions-sa {
  padding: 65px 0 0;
  position: relative;
  background: #f7f7f7;
  .promotions-sa-head {
    height: 59px;
    padding: 0 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 800;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    background-image: linear-gradient(-99deg, #04a0f9 3%, #08b1f9 47%, #08b1f9 100%);
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    .next-arrow {
      padding: 2px 4px;
      background: url('../../img/common/back.svg') no-repeat center center/contain;
      width: 12px;
      height: 22px;
      display: inline-block;
    }
    .promotions-sa-title {
      width: 100%;
      h1 {
        font-size: 19px;
        font-weight: normal;
        text-align: center;
      }
    }
  }

  .promotions-sa-content {
    padding: 20px;
    padding-bottom: 77px + 20px;

    .banner-inner {
      background-color: #f7f7f7;
      @include shadow(0 1px 8px rgba(0, 0, 0, 0.05));
      margin-bottom: 20px;
      border-radius: 10px;

      .banner-inner-wrap img {
        width: 100%;
        border: 2px solid #fff;
        border-radius: 10px;
        @include shadow(0 1px 5px rgba(0, 0, 0, 0.05));
      }
      .banner-body {
        padding: 0 15px 9px;
        font-size: 13px;
        color: #666666;
        position: relative;
        &.inside {
          padding: 15px;
        }
        .arrow {
          float: right;
          color: #46aef7;
          font-size: 13px;
          padding-right: 17px;
          position: relative;
          &:after {
            background: url('../../img/promotions/go-next.svg') no-repeat center center/cover;
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 12px;
            height: 11px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
// 0px 2px 8px 1px rgba(0, 0, 0, 0.02)

// .promotions-sa {
// 	padding: 168px 0 0;
// 	position: relative;
// 	&-head {
// 		@include position(fixed, $z-index: 100, $top: 0, $left: 0, $right: 0, $bottom: auto);
// 	}
// 	&-title {
// 		height: 59px;
// 		@include gradient2(-100deg, #c1ea4f 5%, #8bdc65 45%, #16885f 100%);
// 		h1 {
// 			color: #fff;
// 			text-align: center;
// 			@include font(20px, 500, 59px);
// 		}
// 	}
// 	&-back-wrap {
// 		height: 59px;
// 		display: flex;
// 		align-items: center;
// 		padding: 0 30px;
// 		@include gradient2(-100deg, #c1ea4f 5%, #8bdc65 45%, #16885f 100%);
// 		a {
// 			svg {
// 				fill: #fff;
// 			}
// 			span {
// 				color: #fff;
// 				margin: 0 0 0 7px;
// 				@include font(20px, 500, 29px);
// 			}
// 		}
// 	}
// 	&-content {
// 		padding: 20px 30px;
// 		padding-bottom: 77px + 20px;
// 		.banner {
// 			display: block;
// 			padding: 3px;
// 			@include radius(5px);
// 			@include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
// 			@include gradient2(to right, #c1ea4f 0%, #8bdc65 45%, #16885f 100%);
// 			&:not(:last-child) {
// 				margin: 0 0 15px;
// 			}
// 			&-inner {
// 				background: #fff;
// 				@include radius(2px);
// 				img {
// 					width: 100%;
// 					display: block;
// 				}
// 			}
// 			.banner-title {
// 				padding: 10px;
// 				h4 {
// 					@include font(16px, 500);
// 				}
// 			}
// 			.banner-body {
// 				padding: 10px;
// 				display: flex;
// 				align-items: flex-start;
// 				justify-content: space-between;
// 				overflow-y: auto;
// 				p {
// 					flex: 1;
// 					@include font(15px, 400);
// 				}
// 				button {
// 					color: #fff;
// 					padding: 4px 14px;
// 					@include font(14px, 500);
// 					@include radius(60px);
// 					@include gradient2(to right, #c1ea4f 0%, #8bdc65 45%, #16885f 100%);
// 				}
// 			}
// 		}
// 		&.single {
// 			.banner:last-child {
// 				margin: 0;
// 			}
// 		}
// 		@media screen and (min-width: 620px) {
// 			&:not(.single) {
// 				display: flex;
// 				flex-wrap: wrap;
// 				align-items: flex-start;
// 				.banner {
// 					width: calc(50% - 7.5px);
// 					margin: 0 15px 15px 0 !important;
// 					&:nth-child(2n) {
// 						margin-right: 0 !important;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.decor-n0, .decor-n1 {
// 		pointer-events: none;
// 		background: no-repeat center center/cover;
// 		@include position(absolute, $z-index: -1);
// 	}
// 	.decor-n0 {
// 		background-image: url(../img/promotions/decor-n0.png);
// 		top: 38px;
// 		left: 3px;
// 		right: auto;
// 		@include size(231px, 231px);
// 	}
// 	.decor-n1 {
// 		background-image: url(../img/promotions/decor-n1.png);
// 		top: 10px;
// 		left: auto;
// 		right: 0;
// 		@include size(250px, 195px);
// 	}
// }
