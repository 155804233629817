.user-sa {
  min-width: 320px;
  // background: #fff;
  padding: 5px 20px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: space-around;
  @media screen and (min-width: 520px) {
    padding: 6px 30px;
    justify-content: space-between;
  }
  // @include radius(0 0 20px 20px);
  // @include shadow(0 4px 16px rgba(42, 51, 84, .12));
  .load-spin {
    @include radius(0 0 20px 20px);
    &:before {
      top: calc(50% - (32px / 2)) !important;
      left: calc(50% - (32px / 2)) !important;
      @include size(32px !important, 32px !important);
    }
  }
  .user-sa-login {
    font-size: 11px;
    color: #3b4e73;
    font-family: 'Pingfang Medium';
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 -3px;
      background-image: url('../../img/home/goToLogin_bg.png');
      background-repeat: no-repeat;
      background-size: 100% auto;
      color: #fff;
      padding: 7px 0;
      width: 80px;
      p {
        margin-top: -1px;
      }
    }
    img {
      width: 10px;
      margin-left: 5px;
      filter: brightness(0) invert(1);
      margin-top: -1px;
    }
  }
  .user-sa-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .user-sa-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-sa-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Pingfang Regular';
  }
  .user-sa--name {
    @include textOverflow;
    @include font(15px, 500, 23px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    p {
      margin-right: 5px;
    }
    @for $i from 0 through 10 {
      .vip-badge-#{$i} {
        background-size: 50px auto;
        background-repeat: no-repeat;
        background-position: center center;
        width: 50px;
        height: 20px;
        margin-bottom: -1px;
        background-image: url('../../img/vip/badge/badge_vip#{$i}.png');
      }
    }
  }
  .user-sa--balance {
    color: #55687d;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -2px;
    cursor: pointer;
    outline: none;
    @include textOverflow;
    @include font(14px, 500, 20px);
    button {
      margin: 0 0 0 6px;
      font-size: 0;
      svg {
        @include size(20px, 20px);
      }
    }
    span {
      font-size: 15px;
      color: #fc686f;
      background: linear-gradient(-46deg, #ff934c, #fc686f);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .user-sa--balance p {
    @include font(14px, 600, 100%);
    position: relative;
    color: #ff9500;
    span {
      font-size: 13px;
    }
  }
  .button-logout {
    color: #16885f;
    @include font(18px, 400, 26px);
  }
  .user-sa-manage {
    padding: 0 0 8px;
  }
  .user-sa-hr {
    border-top: 1px dotted #b2afaf;
    border-spacing: 2px;
    margin: 0 2px;
  }
  .user-sa-links {
    margin: 8px 0 8px auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media screen and (max-width: 520px) {
      justify-content: space-around;
      padding: 0 0 0 18px;
    }
    @media screen and (max-width: 375px) {
      padding: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: auto;
      white-space: nowrap;
      position: relative;
      @media screen and (max-width: 426px) {
        margin-right: 10px;
      }
      @media screen and (max-width: 375px) {
        margin-right: 5px;
      }

      &:last-child {
        margin: 0;
      }
      i {
        width: 40px;
        height: 30px;
        margin-top: 1px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        position: relative;
      }
      &.link-debit {
        i {
          background-image: url('../../img/home/withdraw.png');
        }
      }
      &.link-deposit {
        i {
          background-image: url('../../img/home/deposit.png');
        }
      }
      &.link-transfer {
        i {
          background-image: url('../../img/home/transfer.png');
        }
      }
      &.link-inbox {
        i {
          background-image: url('../../img/home/inbox.png');
          .count {
            color: #fff;
            background: #ef2f2f;
            font-size: 9px;
            min-width: 12px;
            text-align: center;
            border-radius: 5px;
            position: absolute;
            margin: 0;
            top: -5px;
            right: -5px;
            z-index: 1;
            font-style: normal;
          }
        }
      }
      span {
        color: #36a4e4;
        font-size: 10px;
        font-family: 'PingFang Medium';
      }
    }
  }
}
