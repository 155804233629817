.menu-sa {
  min-width: 375px;
  @include gradient2(to bottom, transparentize(#fff, 1), #fff);
  @include position(fixed, $z-index: 999, $top: auto, $left: 0, $right: 0, $bottom: 0);
  @media screen and (max-width: 360px) {
    min-width: 100%;
  }
  svg {
    fill: #8e9bae;
  }
  // &-inner {
  //   // ======================= EDIT
  //   // padding: 0 20px 20px 20px;
  // }
  &-wrap {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 520px) {
      justify-content: space-between;
    }
    position: relative;
    background: #fff;
    @include select(none);
    @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
    // @include radius(10px);
    // padding: 0 10px;

    //  ===================== EDIT
    padding: 4px 50px 6px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    //  ===================== EDIT

    .menu--switch {
      pointer-events: none;
      width: 54px;
      height: 54 px;
      background: #16885f;
      @include radius(50%);
      @include position(absolute, $top: -50%, $left: 5px);
      @include transition(transform 0.2s ease);
      // =========================== EDIT
      display: none !important;
    }
  }
  .menu--item {
    position: relative;
    z-index: 12;
    padding: 6.5px;
    // min-width: 70px;
    // ============= EDIT
    min-width: 70px;
    outline: none;
    @media screen and (max-width: 360px) {
      min-width: 60px;
    }
    // ============= EDIT

    .name {
      // color: #55687d;
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
      margin: 5px 0 0;
      @include transition(color 0.2s ease);
      // =============== EDIT
      color: #8e9bae;
      // =============== EDIT
    }
    .icon {
      margin: 0 auto;
      // width: 23px;
      // height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // -webkit-backface-visibility: hidden;
      // -webkit-transform: perspective(1056px);
      // @include transition(transform .2s ease);
      // transition: all .5s ease-in-out;
      svg {
        fill: #8e9bae;
        position: relative;
        z-index: 200;
        // @include transition(fill .2s ease);
      }
      &.home {
        background: url('../../img/profile/svg/home.svg') no-repeat center center/cover;
        width: 24px;
        height: 23px;
      }
      &.promotions {
        background: url('../../img/profile/svg/promotion.svg') no-repeat center center/cover;
        width: 22px;
        height: 22px;
      }
      &.faq {
        background: url('../../img/profile/svg/customer-service.svg') no-repeat center center/cover;
        width: 22px;
        height: 22px;
      }
      &.profile {
        background: url('../../img/profile/svg/profile.svg') no-repeat center center/cover;
        width: 24px;
        height: 24px;
      }
    }
    &.active {
      .name {
        // color: #212533;
        // ============== EDIT
        color: #46aef7;
        background: linear-gradient(-90deg, #1dd5e6, #46aef7);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // ============== EDIT
      }
      // =============================== EDIT
      .icon {
        // @include transform(translateY(-17px));
        svg {
          // fill: #fff;
        }
        &.home {
          background: url('../../img/profile/svg/home_on.svg') no-repeat center center/cover;
        }
        &.promotions {
          background: url('../../img/profile/svg/promotion_on.svg') no-repeat center center/cover;
        }
        &.faq {
          background: url('../../img/profile/svg/customer-service_on.svg') no-repeat center center/cover;
        }
        &.profile {
          background: url('../../img/profile/svg/profile_on.svg') no-repeat center center/cover;
        }
      }

      // =============================== EDIT
    }
  }
  .menu-sa-live-chat {
    opacity: 0;
    pointer-events: none;
    background: #fff;
    transform: scaleY(0);
    transform-origin: bottom;
    @include radius(9px);
    @include position(fixed, $z-index: 1002, $top: 60px, $bottom: 115px, $left: 20px, $right: 20px);
    @include transition(all 0.2s ease);
    @include shadow(0 4px 16px rgba(42, 51, 84, 0.12));
    &.shown {
      opacity: 1;
      pointer-events: auto;
      transform: scaleY(1);
    }
    button {
      @include position(absolute, $z-index: 1002, $top: -40px, $right: 0);
    }
    iframe {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
