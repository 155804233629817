.transaction-record-wrap {
  .transaction-record-wrap-inner {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    background: #f7f7f7;
    .transaction-record-list {
      padding: 0 20px 70px;
      .transaction-record-items {
        margin: 0 0 10px;
        background: #fff;
        display: block;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.02);
        padding: 10px 0 10px 45px;
        position: relative;

        i {
          display: inline-block;
          margin: 0 auto 3px auto;
          background: no-repeat center center/contain;
          position: absolute;
          left: 11px;
          top: 50%;
          transform: translateY(-50%);
          width: 28px;
          height: 27px;
        }
        &.deposit-record i {
          background-image: url('../../img/transaction-record/deposit-history.svg');
        }
        &.withdrawal i {
          background-image: url('../../img/transaction-record/withdrawal-history.svg');
        }
        &.transfer i {
          background-image: url('../../img/transaction-record/transfer-history.svg');
        }
        &.preferential i {
          background-image: url('../../img/transaction-record/promo-history.svg');
        }
        &.backwater i {
          background-image: url('../../img/transaction-record/rebate-history.svg');
        }

        p {
          color: #39404d;
          text-align: left;
          font-size: 14px;
          line-height: 32px;
        }

        span {
          background-image: url('../../img/transaction-record/next-arrow.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          width: 6px;
          height: 12px;
          content: '';
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
