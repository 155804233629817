.qr-desktop {
  text-align: center;
  .qr-inner {
    height: 698px;
    box-sizing: border-box;
  }
  h1 {
    padding-top: 50px;
    @include font(14px, 500, 1.4);
  }
  .qr-wrap {
    box-sizing: border-box;
    padding: 10px;
    margin: 30px auto 25px;
    height: 210px;
    width: 210px;
    @include radius(10px);
    @include shadow(0 4px 14px rgba(#2a3354, 0.12));
    .load-spin {
      @include radius(10px);
    }
  }
  p {
    color: #55687d;
    @include font(14px, 500, 1.4);
  }
}
