.app-download {
  max-width: 1200px;
  margin: 145px auto 65px;
  .app-download-inner {
    display: flex;
    .app-d-col {
      flex: 50%;
      position: relative;
    }
    .app-download-content {
      .head-block {
        .head-title {
          font-family: Lato;
          color: #46aef7;
          line-height: 65px;
          font-size: 65px;
          position: relative;
          display: inline-block;
          margin-bottom: 2px;
          & span {
            font-size: 49.4px;
            position: relative;
            top: -7px;
            margin-left: 8px;
          }
        }
        .head-sub {
          color: #ecf0fc;
          font-size: 24.56px;
          font-family: Metropolis;
          margin-left: 5px;
        }
        .head-text {
          color: #2a2a2a;
          font-size: 14px;
          line-height: 30px;
          max-width: 335px;
          margin: 32px 0 60px;
          margin-left: 5px;
        }
      }

      .app-d-body {
        display: flex;
        .app-d-b-item {
          text-align: center;
          margin-right: 25px;
          .app-d-b-image {
            display: inline-block;
            width: 100%;
            height: 102px;
            position: relative;
          }
          &:nth-child(1) {
            .app-d-b-image:before {
              content: "";
              background: url('../../img/app-download/qr-code.png') no-repeat
                center center/cover;
              width: 102px;
              height: 102px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          &:nth-child(2) {
            margin: 0;
            .app-d-b-image:before {
              content: "";
              background: url('../../img/app-download/h5.svg') no-repeat center
                center/cover;
              width: 74px;
              height: 83px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .app-d-b-text {
            color: #3b414d;
            font-size: 12px;
            margin-bottom: 2px;
          }
          .app-d-b-link {
            font-size: 12px;
            color: #46aef7;
          }
        }
      }
    }
    .app-download-image {
      height: 560px;
      .app-d-image {
        width: 631px;
        height: 591px;
        position: absolute;
        &.left {
          background: url('../../img/app-download/pwa1.JPEG') no-repeat center
            center/cover;
          left: 0;
          top: 41px;
        }
        &.front {
          background: url('../../img/app-download/app-img.png') no-repeat center
            center/cover;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: 2;
        }
        &.right {
          background: url('../../img/app-download/pwa2.JPEG') no-repeat center
            center/cover;
          right: 0;
          top: 21px;
        }
      }
    }
  }
}
// .app-download {
// 	$side-padding: 20px;
// 	background: url(../../img/bgs/bg-grey@1.png) no-repeat top 170px left calc(50% + 120px);
// 	min-width: 1360px + ($side-padding * 2);
// 	&-inner {
// 		width: 1360px + ($side-padding * 2);
// 		margin: 0 auto;
// 		padding: 0 $side-padding;
// 		padding-bottom: 170px;
// 	}
// 	.ad-flex {
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		.ad-phone {
// 			width: 675px;
// 			height: 634px;
// 			background: url(../../img/app-download/phone-wrap.png) no-repeat center center/cover;
// 		}
// 		.ad-links {
// 			padding: 64px 216px 0 0;
// 			&-wrap {
// 				display: flex;
// 			}
// 			h4 {
// 				color: #212533;
// 				margin: 0 0 27px;
// 				@include font(32px, 400, 1.3);
// 			}
// 			.ad-link {
// 				font-size: 0;
// 				position: relative;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				min-height: 179px;
// 				@include radius(20px);
// 				@include gradient2(to right, #c1ea4f 0%, #8bdc65 45%, #16885f 100%);
// 				& > p {
// 					margin: 0 !important;
// 					color: #000 !important;
// 					font-size: 33px !important;
// 					font-weight: 700 !important;
// 					line-height: 1.3 !important;
// 				}
// 				&:after {
// 					content: '';
// 					background: #fff;
// 					@include radius(16px);
// 					@include position(absolute, $z-index: 5, $top: 4px, $left: 4px, $right: 4px, $bottom: 4px);
// 				}
// 				&-wrap {
// 					width: 187px;
// 					&:not(:last-child) {
// 						margin: 0 21px 0 0;
// 					}
// 					p {
// 						color: #55687d;
// 						text-align: center;
// 						margin: 15px 0 0;
// 						@include font(22px, 400, 1.3);
// 					}
// 					p, svg {
// 						@include position(relative, $z-index: 10);
// 					}
// 				}
// 			}
// 		}
// 	}
// }
