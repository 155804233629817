.ui-tabs {
  font-size: 0;
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0 0 45px;
  @include radius(60px);
  @include shadow(
    0 0 44px rgba(24, 24, 25, 0.07),
    inset 0 -2px 4px rgba(79, 95, 77, 0.9),
    inset 0 2px 4px rgba(0, 0, 0, 0.25)
  );
  &--tab {
    width: 100%;
    white-space: nowrap;
    color: #000;
    cursor: pointer;
    text-align: center;
    padding: 9px;
    @include font(20px, 400, 29px);
    @include select(none);
    @include position(relative, $z-index: 100);
    @include transition(color 0.2s ease-in-out);
    &.active {
      color: #fff;
    }
    &.disabled {
      opacity: 0.2;
      cursor: default;
      pointer-events: none;
    }
  }
}
