@import './helpers/template';
@import './common/template';
@import './login-register/template';
@import './pages/template';
@import './history/template';
@import './home/template';
@import './profile/template';
@import './translate/en.scss';

body.body-pwa {
  * {
    user-select: text;
    -webkit-user-select: text;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    outline: none;
  }
}

body.no-scroll {
  overflow: hidden;
  touch-action: none;
  position: fixed;
  width: 100%;
  height: 100%;
}

.app-sa {
  min-height: 100vh;
  width: 100vw;
  background-color: #f3f8fe;
  .app-sa-head {
    width: 100vw;
    margin-bottom: 10px;
    background-color: #fff;
    overflow: hidden;
    @include radius(0 0 20px 20px);
    box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.16);
  }
  .app-sa-head::after {
    content: '';
    background-color: #fff;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .app-sa-header {
    width: 100vw;
    padding: 10px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .app-sa-ticker {
    white-space: nowrap;
    position: relative;
    height: 23px;
    padding: 0 12px;
    cursor: pointer;
    .ticker {
      p {
        color: #46aef7;
        font-size: 14px;
        font-family: 'Pingfang Regular';
      }
    }
  }

  // TOOL TIP
  .tool-tip-copied {
    position: absolute;
    display: none;
    background: rgba(66, 66, 66, 0.72);
    color: #fff;
    width: 114px;
    padding: 8px 0;
    text-align: center;
    border-radius: 10px;
    right: -90px;
    bottom: 35px;
    font-size: 14px;
    &.show {
      display: block;
    }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 15px solid rgba(66, 66, 66, 0.72);
      position: absolute;
      top: calc(100% - 5px);
      left: -5px;
      transform: rotate(34deg);
    }
    @media screen and (max-width: 375px) {
      right: -60px;
    }
  }
}

// @import './translate/en.scss';
