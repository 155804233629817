.mb-picker {
  position: fixed;
  z-index: -100;
  bottom: -100%;
  left: 0;
  width: 100vw;
  height: 270px;
  background-color: #ffffff;
  padding: 40px 20px 20px;
  transition: all 0.2s ease-in-out;
  * {
    font-family: 'PingFang Medium';
  }
  img {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 15px;
  }
  .items {
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    padding: 39px 0;
    height: 117px;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    button {
      scroll-snap-align: start;
      background-color: transparent;
      width: 100%;
      height: 39px;
      padding: 10px 0;
      color: #676570;
    }
  }
  .submit {
    border-radius: 4px;
    button {
      color: #fff;
      background: linear-gradient(180deg, #1ed3e6 0%, #31c1ef 46%, #44aff7 100%) !important;
    }
  }
  .bg {
    position: fixed;
    bottom: 270px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 270px);
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: -1000;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .picker-highlight {
    position: fixed;
    z-index: -1;
    bottom: 152px;
    left: 20px;
    width: calc(100% - 40px);
    height: 39px;
    background-image: linear-gradient(-180deg, #1ed3e6, #43b0f7);
    border-radius: 4px;
    opacity: 0;
    &:before {
      content: '';
      width: calc(100% - 2px);
      height: 37px;
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 4px;
      background-color: #fff;
      z-index: -15;
    }
  }
}

.mb-picker-active {
  bottom: 0;
  z-index: 1000;
  .bg {
    opacity: 1;
  }
  .picker-highlight {
    opacity: 1;
  }
}
