.payment {
  padding: 50px 0;
  max-width: 700px;
  margin: 0 auto;
  * {
    font-family: PingFang Medium;
  }
  @media (min-width: 1200px) {
    padding-bottom: 50px;
  }
  .payment-header {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 50px;
    background-image: url('../../img/common/header_bg.png');
    background-size: 100% 80px;
    background-position: center;
    box-shadow: 0.75px 1.25px 3px 0px rgba(51, 51, 51, 0.16);
    p {
      line-height: 50px;
      font-size: 18px;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 8px;
    }
  }
  .payment-cancel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    background-color: #e6ebf2;
    p {
      color: #575e66;
      font-size: 17px;
    }
    button {
      color: #fff;
      font-style: italic;
      text-shadow: 1px 1px #ccc;
      padding: 7px 20px;
      border-radius: 6px;
      background-image: linear-gradient(0deg, rgb(30, 211, 230) 0%, rgb(49, 193, 239) 46%, rgb(68, 175, 247) 100%);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
    }
  }
  .payment-details {
    padding: 10px 10px 30px;
    .detail-item {
      margin-bottom: 15px;
      position: relative;
      &:nth-child(5) {
        .label {
          span {
            color: #cc0001;
          }
        }
      }
      .label {
        color: #3b4e73;
        font-family: PingFang Bold;
        font-size: 14px;
        margin-bottom: 7px;
        &::before {
          content: '*';
          margin-right: 4px;
          color: #cc0001;
        }
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        height: 35px;
        font-size: 15px;
        padding: 7px 10px;
        width: calc(100% - 70px);
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 1px 3px 0px rgba(23, 23, 23, 0.24);
      }
      button {
        position: relative;
        color: #fff;
        padding: 7px 15px;
        border-width: 1px;
        border-color: rgb(66, 181, 248);
        border-style: solid;
        border-radius: 4px;
        background-color: rgb(66, 181, 248);
        box-shadow: 0px 1px 3px 0px rgba(23, 23, 23, 0.24);
        &::before {
          content: '';
          position: absolute;
          top: -10px;
          right: -7px;
          background: url(../../img/profile/svg/correct.svg) no-repeat center center/contain;
          width: 20px;
          height: 20px;
          opacity: 0;
        }
        &.active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
    .tip {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      background-color: #cc0001;
      color: #fff;
      border-radius: 4px;
      padding: 7px 10px;
      font-size: 12px;
      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 30px;
        border: 5px solid transparent;
        border-bottom-color: #cc0001;
      }
    }
  }
  .instructions {
    padding: 10px;
    font-size: 14px;
    line-height: 23px;
    color: #575e66;
    .red {
      color: #cc0001;
    }
  }
}
