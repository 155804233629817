.comission-report-desktop {
  .card-head {
    background-image: linear-gradient(#c3cfe2, #f5f7fa);
    padding: 16px 20px;
    height: 46px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    .card-title {
      color: #4796ff;
      font-size: 14px;
      span {
        width: 25%;
        display: inline-block;
        // text-align: center;
        &.active {
          // color: #fb6d47;
        }
      }
    }
  }
  .card-body {
    height: 650px;
    overflow-y: auto;
    .no-transactions {
      height: 416px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #95969a;
      padding-top: 66px;
      .image-box {
        background: url('../../../../assets/img/profile/svg/open-box.svg') no-repeat center
          center/contain;
        width: 158px;
        height: 137px;
        display: inline-block;
        margin-bottom: 23px;
      }
      span {
        font-size: 12px;
      }
    }

    .card-item {
      width: 100%;
      height: 66px;
      box-sizing: border-box;
      padding: 19px 20px;
      border-bottom: 1px solid #f1efef;
      border-left: 1px solid #f1efef;
      border-right: 1px solid #f1efef;
      background: #fff;
      &:last-child {
        margin: 0;
      }
      span {
        width: 25%;
        display: inline-block;
        @include font(14px, 500, 1.4);
        &:first-child {
          color: #212533;
        }
        &:last-child {
          color: #95969a;
        }
      }
    }
  }
}
